import HeroBanner1 from "../images/sbihm/HeroBanner-1.jpg";
import HeroBanner2 from "../images/sbihm/HeroBanner-2.jpg";
import FrontBanner from "../images/banner.png";

import image1 from "../images/sbihm/gallery_1.png";
import image2 from "../images/sbihm/gallery_9.jpg";
import image3 from "../images/sbihm/gallery_3.png";
import image4 from "../images/sbihm/gallery_8.png";
import image5 from "../images/banner.png";

export const HeroImages = [
  {
    id: 1,
    src: HeroBanner1,
    alt: "Hero Banner 1",
    title: "Hero Banner 1",
  },
  {
    id: 2,
    src: HeroBanner2,

    alt: "Hero Banner 2",
    title: "Hero Banner 2",
  },
];

export const frontImage = {
  id: 1,
  src: FrontBanner,
  alt: "Hero Banner 1",
  title: "Hero Banner 1",
};
export const slidingImages = [image1, image2, image3, image4, image5];
