import * as React from "react";
import { Grid, Typography, TextField, Button, Hidden } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {
  DegreeCourses,
  CertificateCourses,
  DiplomaCourses,
} from "../utils/courses";
import { styled } from "@mui/material/styles";
import Loader from "./Loader";
import { contactEmail } from "../utils/reviews";

export default function Map() {
  const courses = [...DegreeCourses, ...DiplomaCourses, ...CertificateCourses];
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({
    name: "",
    email: "",
    phone: "",
    course: "",
    message: "",
    _formsubmit_id: "",
  });
  const fillCategoryDropDown = () => {
    return courses.map((item) => {
      return <MenuItem value={item.id}>{item.name}</MenuItem>;
    });
  };

  const [err, setErr] = React.useState({
    name: false,
    email: false,
    phone: false,
  });
  const checkError = () => {
    if (data.name === "") {
      setErr((err) => ({ ...err, name: true }));
      return true;
    }
    if (data.email === "") {
      setErr((err) => ({ ...err, email: true }));
      return true;
    }
    if (data.phone === "") {
      setErr((err) => ({ ...err, phone: true }));
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    setData(() => ({ ...data, [e.target.name]: e.target.value }));
    if (e.target.value !== "")
      setErr(() => ({ ...err, [e.target.name]: false }));
  };

  const onSubmit = () => {
    //send data via post method to url
    if (checkError()) return;
    setLoading(true);

    fetch("https://formsubmit.co/ajax/" + contactEmail, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  return (
    <StyledContainer container>
      <Loader loading={loading} />
      <Hidden smDown xsDown>
        <Grid
          lg={6}
          sm={12}
          xs={12}
          item
          sx={{
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7382.670177456475!2d87.28927901818415!3d22.303163467487266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d46a934d00f75%3A0x8b0fd208a4e83cba!2sSouth%20Bengal%20Institute%20Of%20Hotel%20Management!5e0!3m2!1sen!2sin!4v1683372230016!5m2!1sen!2sin"
            width="100%"
            height="500"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="SBIHM location"
            style={{ border: 0, borderRadius: "12px 0 0 12px" }}
          ></iframe>
        </Grid>
      </Hidden>

      <FormContainer xs={12} lg={6}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: 5 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "28px",
                letterSpacing: "0.02em",
                textAlign: "left",
                color: "#0A253D",
              }}
            >
              Get in touch with us
            </Typography>
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "28px",
                letterSpacing: "0.02em",
                textAlign: "left",
                color: "#666666",
                marginBottom: "10px",
              }}
            >
              Fill out the form and our team will get back to you
            </Typography>
          </div>

          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: 50 } }}
            name="name"
            value={data.name}
            onChange={handleChange}
            error={err.name}
            required
          />

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: 50 } }}
            style={{ marginTop: 10 }}
            name="email"
            value={data.email}
            onChange={handleChange}
            error={err.email}
            required
          />
          <TextField
            label="Phone No."
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: 50 } }}
            style={{ marginTop: 10 }}
            name="phone"
            value={data.phone}
            onChange={handleChange}
            error={err.phone}
            required
          />
          <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
            <InputLabel id="demo-simple-select-label">Courses</InputLabel>
            <Select
              name="course"
              value={data.course}
              onChange={handleChange}
              style={{ borderRadius: 50 }}
            >
              {fillCategoryDropDown()}
            </Select>
          </FormControl>
          <TextField
            label="Message"
            multiline
            rows={3}
            maxRows={5}
            fullWidth
            InputProps={{ style: { borderRadius: 20 } }}
            style={{ marginTop: 10 }}
            name="message"
            value={data.message}
            onChange={handleChange}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: 2, borderRadius: 20 }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </FormContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",

  height: "600",
  padding: 5,

  "@media (max-width: 600px)": {
    display: "flex",
    alignItems: "center",
    padding: 2,
  },
});

const FormContainer = styled(Grid)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 5,
  height: "500px",
  borderTopRightRadius: "12px",
  borderEndEndRadius: "12px",
  backgroundColor: "#1E73BE14",
  "@media (max-width: 600px)": {
    display: "flex",
    alignItems: "center",
    padding: 20,
    borderRadius: "12px",
  },
});
