import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";

const SuccessMessage = styled(Typography)({
  display: "flex",
  alignItems: "center",
  color: "#fff",
  backgroundColor: "#4caf50",
  padding: "20px",
  borderRadius: "10px",
  justifyContent: "center",
});

export default function SimpleBackdrop({ loading }) {
  const [open, setOpen] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setShowSuccessMessage(false);
  };

  React.useEffect(() => {
    if (loading) {
      handleOpen();
    } else {
      setTimeout(() => {
        setShowSuccessMessage(true);
        setTimeout(() => {
          handleClose();
        }, 2000);
      }, 2000);
    }
  }, [loading]);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        {showSuccessMessage ? (
          <SuccessMessage variant="h6">
            <CheckCircleIcon sx={{ margin: "20px", fontSize: "50px" }} />
            Thank you for contacting,
            <br /> we shall get back shortly!
          </SuccessMessage>
        ) : (
          <CircularProgress color="primary" />
        )}
      </Backdrop>
    </div>
  );
}
