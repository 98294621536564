import React, { useState } from "react";

import { Grid, Typography, TextField, Button, Hidden } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import contact from "../../images/contact.png";
import {
  DegreeCourses,
  DiplomaCourses,
  CertificateCourses,
} from "../../utils/courses";
import "../../style.css";
import { styled } from "@mui/material/styles";
import { contactEmail } from "../../utils/reviews";
import Loader from "../../component/Loader";

export default function Map() {
  const courses = [...DegreeCourses, ...DiplomaCourses, ...CertificateCourses];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    course: "",
    message: "",
    _formsubmit_id: "",
  });

  const [err, setErr] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const checkError = () => {
    if (data.name === "") {
      setErr((err) => ({ ...err, name: true }));
      return true;
    }
    if (data.email === "") {
      setErr((err) => ({ ...err, email: true }));
      return true;
    }
    if (data.phone === "") {
      setErr((err) => ({ ...err, phone: true }));
      return true;
    }
    return false;
  };

  const fillCategoryDropDown = () => {
    return courses.map((item) => {
      return (
        <MenuItem id={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      );
    });
  };

  const handleChange = (e) => {
    setData(() => ({ ...data, [e.target.name]: e.target.value }));
    if (e.target.value !== "")
      setErr(() => ({ ...err, [e.target.name]: false }));
  };

  const onSubmit = () => {
    //send data via post method to url
    if (checkError()) return;
    setLoading(true);
    fetch("https://formsubmit.co/ajax/" + contactEmail, {
      method: "POST",
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setData({
            name: "",
            email: "",
            phone: "",
            course: "",
            message: "",
            _formsubmit_id: "",
          });
        }
      })
      .catch((err) => setLoading(false));
  };

  return (
    <Grid
      container
      sx={{
        padding: 5,
        display: "flex",
        alignItems: "center",
        backgroundImage: `url(${contact})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",

        height: "600",
      }}
    >
      <Loader loading={loading} />
      <Grid xs={12} sx={{ marginBottom: 3 }}>
        <Title>Contact Us</Title>
      </Grid>
      <Hidden smDown xsDown>
        <Grid
          lg={6}
          sm={12}
          xs={12}
          item
          sx={{
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7382.670177456475!2d87.28927901818415!3d22.303163467487266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1d46a934d00f75%3A0x8b0fd208a4e83cba!2sSouth%20Bengal%20Institute%20Of%20Hotel%20Management!5e0!3m2!1sen!2sin!4v1683372230016!5m2!1sen!2sin"
            width="100%"
            height="500"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="SBIHM location"
            style={{ border: 0, borderRadius: "12px 0 0 12px" }}
          ></iframe>
        </Grid>
      </Hidden>

      <StyledContainer xs={12} lg={6}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: 5 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "28px",
                letterSpacing: "0.02em",

                color: "#0A253D",
              }}
            >
              Get in touch with us
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                letterSpacing: "0.02em",

                color: "#666666",
                marginBottom: "10px",
              }}
            >
              Fill out the form and our team will get back to you
            </Typography>
          </div>
          <form action={"https://formsubmit.co/" + contactEmail} method="POST">
            <input type="text" name="_honey" style={{ display: "none" }} />
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{ style: { borderRadius: 50 } }}
              name="name"
              value={data.name}
              onChange={handleChange}
              error={err.name}
              required
            />

            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{ style: { borderRadius: 50 } }}
              style={{ marginTop: 10 }}
              name="email"
              value={data.email}
              onChange={handleChange}
              error={err.email}
              required
            />
            <TextField
              label="Phone No."
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{ style: { borderRadius: 50 } }}
              style={{ marginTop: 10 }}
              name="phone"
              value={data.phone}
              onChange={handleChange}
              error={err.phone}
              required
            />
            <FormControl fullWidth size="small" sx={{ marginTop: "10px" }}>
              <InputLabel id="demo-simple-select-label">Courses</InputLabel>
              <Select
                // value={categoryId}
                // label="Category Name"
                // onChange={(event) => setCategoryId(event.target.value)}
                // InputProps={{ style: { borderRadius: 50 } }}
                style={{ borderRadius: 50 }}
                name="course"
                value={data.course}
                onChange={handleChange}
              >
                {fillCategoryDropDown()}
              </Select>
            </FormControl>
            <TextField
              label="Message"
              multiline
              rows={3}
              maxRows={5}
              fullWidth
              InputProps={{ style: { borderRadius: 20 } }}
              style={{ marginTop: 10 }}
              name="message"
              value={data.message}
              onChange={handleChange}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ marginTop: 2, borderRadius: 20 }}
              onClick={onSubmit}
              // type="submit"
            >
              Submit
            </Button>
          </form>
        </div>
      </StyledContainer>
    </Grid>
  );
}

const StyledContainer = styled(Grid)({
  padding: "20px",
  borderTopRightRadius: "12px",
  borderEndEndRadius: "12px",
  backgroundColor: "#FFFFFF",
  height: "500px",

  "@media (max-width: 600px)": {
    borderRadius: "12px",
  },
});

const Title = styled(Typography)({
  textAlign: "center",
  fontFamily: "Noto Serif",
  fontSize: "40px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: " 0.02em",
  color: "white",
  width: "100%",
  "@media (max-width: 600px)": {
    fontSize: "37px",
    margin: "1rem 0.4rem",
  },
});
