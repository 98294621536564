import React, { useState, useEffect } from "react";
import { Box, Typography, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/styled-engine";
import logo from "../images/logo.jpg";

const fadeIn = keyframes`
  0% {
    opacity: 1;
    
    background: rgba(255, 255, 255, 1);
    visibility: visible;
  }
  50% {
    opacity: 1;
    
    background: rgba(255, 255, 255, 0.9);
  }
  100% {
    opacity: 0;
    
    background: rgba(255, 255, 255, 0.5);
    visibility: hidden;
  }
`;

const Typewriter = () => {
  const [text, setText] = useState("");
  const words = ["S", "b", "B", "I", "H", "M", ""];

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      setText((prevText) => prevText + words[i]);
      i++;
      if (i === words.length) clearInterval(interval);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return <StyledTitle>{text}</StyledTitle>;
};

const FlashScreen = ({ view }) => {
  const [show, setShow] = useState(view);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, []);
  return (
    show && (
      <Container show={show}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",

            //   border: "1px solid black",
          }}
        >
          <Fade in timeout={1500}>
            <img src={logo} height={100} width={100} alt="logo" />
          </Fade>

          <Fade in timeout={1500}>
            <StyledTitle>SBIHMKGP</StyledTitle>
          </Fade>
        </div>
      </Container>
    )
  );
};

const Container = styled(Box)(({ show }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: show ? "flex" : "none",
  justifyContent: "center",
  alignItems: "center",
  animation: `${fadeIn} 3s ease-in-out`,
  visibility: show ? "visible" : "hidden",
  "& span": {
    display: "inline-block",
    fontSize: "50px",
    animation: ` ease-in-out 3s `,
    "&:not(:last-child)": {
      marginRight: "20px",
    },
  },
  zIndex: 9999,
}));

const StyledTitle = styled(Typography)({
  color: "#1E73BE",
  fontSize: "60px",
  fontFamily: "Noto Serif",
});

export default FlashScreen;
