import React, { useState } from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddIcon from "@mui/icons-material/Add";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import popupBg from "../../images/popup_bg.png";
import {
  DegreeCourses,
  CertificateCourses,
  DiplomaCourses,
} from "../../utils/courses";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LazyLoad from "react-lazy-load";

export default function OpeningDialog({ open, handleClose }) {
  var navigate = useNavigate();

  const [edu, setEdu] = useState(12);
  const [course, setCourse] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [err, setErr] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const checkError = () => {
    if (name === "") {
      setErr((err) => ({ ...err, name: true }));
      return true;
    }
    if (email === "") {
      setErr((err) => ({ ...err, email: true }));
      return true;
    }
    if (phone === "") {
      setErr((err) => ({ ...err, phone: true }));
      return true;
    }
    return false;
  };

  const fillCourseDropDown = () => {
    const courses =
      edu === 10 ? DiplomaCourses : [...DegreeCourses, ...CertificateCourses];
    return courses.map((item) => {
      return (
        <StyledMenuItem id={item.id} value={item.name}>
          {item.name}
        </StyledMenuItem>
      );
    });
  };

  const handleEduChange = (event) => {
    setEdu(event.target.value);
  };

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  function gotowhatsapp() {
    if (checkError()) return;
    var url =
      "https://wa.me/917407558559?text=" +
      "Name: " +
      name +
      "%0a" +
      "Phone: " +
      phone +
      "%0a" +
      "Email:" +
      email +
      "%0a" +
      "Course: " +
      course;
    window.open(url, "blank").focus();
  }
  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogActions>
        <IconButton onClick={handleClose} style={{ color: "white" }}>
          <CloseRoundedIcon sharp autofocus />
        </IconButton>
      </DialogActions>

      <DialogContent>
        <Grid item>
          <Grid sx={{ marginBottom: 5 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "28px",
                letterSpacing: "0.02em",
                textAlign: "center",
                color: "white",
              }}
            >
              Get in touch with us
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "28px",
                letterSpacing: "0.02em",
                textAlign: "center",
                color: "white",
              }}
            >
              If you are interested or have any questions, send us a message.
            </Typography>
          </Grid>
          <StyledInputField
            label="Name"
            variant="outlined"
            fullWidth
            size="small"
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErr((err) => ({ ...err, name: false }));
            }}
            error={err.name}
            required
          />

          <StyledInputField
            label="E-mail"
            variant="outlined"
            fullWidth
            size="small"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setErr(() => ({ ...err, [e.target.name]: false }));
            }}
            error={err.email}
            required
          />

          <StyledInputField
            label="Phone No."
            variant="outlined"
            fullWidth
            size="small"
            type="number"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setErr(() => ({ ...err, [e.target.name]: false }));
            }}
            error={err.phone}
            required
          />
          <StyledForm fullWidth size="small">
            <StyledInputLabel id="demo-simple-select-label">
              Select Education
            </StyledInputLabel>
            <StyledSelect
              value={edu}
              label="Select Education"
              onChange={handleEduChange}
            >
              <StyledMenuItem value={10}>{"10 (Secondary)"}</StyledMenuItem>
              <StyledMenuItem value={12}>
                {"10+2(Higher Secondary)"}
              </StyledMenuItem>
            </StyledSelect>
          </StyledForm>
          <StyledForm fullWidth size="small">
            <StyledInputLabel id="demo-simple-select-label">
              Interested Course
            </StyledInputLabel>
            <StyledSelect
              value={course}
              label="Interested Course"
              onChange={handleCourseChange}
            >
              {fillCourseDropDown()}
            </StyledSelect>
          </StyledForm>
          <StyledButton fullWidth variant="contained" onClick={gotowhatsapp}>
            <>
              Send to WhatsApp{" "}
              <WhatsAppIcon style={{ marginBottom: 3, marginLeft: 10 }} />
            </>
          </StyledButton>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
}
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 20,
    backgroundImage: "url(" + popupBg + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    boxShadow: "none",
    overflow: "hidden",
  },
}));

const StyledInputField = styled(TextField)(({ theme }) => ({
  marginBottom: 10,

  "& .MuiOutlinedInput-root": {
    borderColor: "white",
    borderRadius: 50,
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red",
    },
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInputBase-input": {
    backgroundColor: "white",
    borderRadius: 50,
  },
}));

//same styled select component with form control
const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: 50,
  "& .MuiSelect-outlined": {
    borderColor: "white",
    borderRadius: 50,
    background: "white",
    "&:hover": {
      borderColor: "white",
    },
    "&.Mui-focused": {
      borderColor: "red",
    },
  },

  "& .MuiSelect-icon": {
    color: "black",
  },
}));

//same styled select component with form control
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderColor: "white",
    borderRadius: 50,
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red",
    },
  },
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInputBase-input": {
    backgroundColor: "white",
    borderRadius: 50,
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "grey",
  "&.Mui-focused": {
    color: "black",
  },
}));

const StyledForm = styled(FormControl)(({ theme }) => ({
  marginBottom: 10,
  borderColor: "white",
  borderRadius: 50,
  "& .MuiOutlinedInput-root": {
    borderColor: "white",
    borderRadius: 50,
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "red",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 50,
  backgroundColor: "#50c47a",
  color: "white",
  textTransform: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "&:hover": {
    backgroundColor: "#0A253D",
    color: "white",
  },
}));
