import { Grid, Button, Typography, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LazyLoad from "react-lazy-load";
import PrimaryButton from "../../component/Primarybutton";
import Secondarybutton from "../../component/Secondarybutton";

export default function C1({ courseType, name, image }) {
  const navigate = useNavigate();

  const goToCourseDetails = () => {
    var scrollDiv = document.getElementById("courseDetails").offsetTop - 100;
    window.scrollTo({ top: scrollDiv, behavior: "smooth" });
  };
  return (
    <Grid container sx={{ backgroundColor: "#1E73BE14" }}>
      <Grid xs={12} md={6} lg={6} sx={{ padding: 10 }}>
        <div className="style_course_heading">
          <Typography
            style={{
              fontFamily: "DM Sans",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "40px",
              letterSpacing: "0.02em",
              textAlign: "left",
              color: "#1E73BE",
            }}
          >
            {courseType}
          </Typography>
        </div>
        <div>
          <Title>{name}</Title>
        </div>
        <Hidden mdUp>
          <ButtonContainer>
            <Secondarybutton
              size="small"
              variant="contained"
              style={{
                border: "1px solid grey",

                width: "80%",
              }}
              onClick={goToCourseDetails}
            >
              About the Course
            </Secondarybutton>
            <PrimaryButton
              size="small"
              onClick={() => navigate("/apply")}
              style={{
                width: "60%",
              }}
            >
              Apply Now
            </PrimaryButton>
          </ButtonContainer>
        </Hidden>

        <Hidden mdDown>
          <ButtonContainer>
            <Button
              sx={{
                borderRadius: 15,
                marginTop: "20px",
                width: "50%",
                padding: "10px 0 ",
                borderColor: "#0A253D",
                color: "#0A253D",
                textTransform: "none",
                fontWeight: 400,
                fontSize: "15px",
              }}
              size="small"
              variant="outlined"
            >
              About the Course
            </Button>
            <Button
              onClick={() => navigate("/apply")}
              sx={{
                borderRadius: 15,
                marginTop: "10px",
                width: "50%",
                padding: "10px 0 ",
                textTransform: "none",
                fontWeight: 400,
                fontSize: "15px",
              }}
              size="small"
              variant="contained"
            >
              Apply Now
            </Button>
          </ButtonContainer>
        </Hidden>
      </Grid>

      <Grid xs={12} md={6} lg={5} sx={{ overflow: "hidden" }}>
        <LazyLoad height={"400px"}>
          <img src={image} width={"100%"} style={{ borderRadius: 20 }} />
        </LazyLoad>
      </Grid>
    </Grid>
  );
}

const ButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",

  "@media (max-width: 600px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "70vw",
    marginLeft: -30,
    marginTop: 20,
    // border: "1px solid black",
  },
});

const Title = styled("div")({
  fontFamily: "Noto Serif",
  fontSize: "40px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: "0.02em",
  color: "#0A253D",

  "@media (max-width: 600px)": {
    fontSize: "30px",
    lineHeight: "30px",
    textAlign: "center",
    marginTop: 20,
  },
});
