import React from "react";
import { Grid } from "@mui/material";
import Header from "../../pages/Home/Header";
import Apply from "./Apply";
import ApplyM from "./ApplyM";
import H3 from "../../pages/Home/H3";
import { FoodBank } from "@mui/icons-material";
import Footer from "../../component/Footer";
import NavBar from "../../component/Navbar";
import FlashScreen from "../../component/splash2";

export default function ApplyPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  return (
    <Grid container sx={{}}>
      <FlashScreen view />
      <Grid item xs={12}>
        <NavBar />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "6rem" }}>
        <Apply />
      </Grid>
      <Grid item xs={12}>
        <ApplyM />
      </Grid>
      <Grid item xs={12}>
        <H3 />
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}
