import React, { useState, useEffect } from "react";
import { Grid, Hidden } from "@mui/material";
import LazyLoad from "react-lazy-load";
import { styled } from "@mui/material/styles";
import banner from "../../images/banner.png";

export default function H1() {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // border: "1px solid black",
      }}
    >
      <Grid lg={6} xs={12}>
        {/* <Hidden smDown xsDown>
          <div
            style={{
              backgroundColor: "#1E73BE0A",
              height: 220,
              width: 170,
              borderTopRightRadius: "12px",
              borderEndEndRadius: "12px",
            }}
          ></div>
        </Hidden> */}
        <div
          style={{
            width: "83%",
            padding: "10px",
            marginLeft: "8%",
          }}
        >
          <Styledheading>We produce excellence</Styledheading>
          <div
            sx={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "30px",
              letterSpacing: "0.02em",
              marginTop: "30px",
              alignSelf: "stretch",
              color: "#666666",
            }}
          >
            We are an institution providing education in the field of
            Hospitality & Tourism Management. The main objective of SBIHMKGP is
            to produce manpower in hospitality industry according to the
            hospitality demand and the goal of SBIHMKGP is to give the proper
            professional hospitality education for our students to make their
            bright future, an ISO 9001:2015.
          </div>
          <div
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "36px",
              letterSpacing: "0.02em",
              marginTop: "10px",
              color: "#666666",
            }}
          >
            A gateway to your future from here in Kharagpur..
          </div>
        </div>
      </Grid>
      <Grid item lg={6} xs={12}>
        <StyledLazyLoad>
          <img
            src={banner}
            style={{
              width: "100%",
              borderRadius: "12px",
            }}
          />
        </StyledLazyLoad>
      </Grid>
    </Grid>
  );
}

const StyledLazyLoad = styled(LazyLoad)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: 300,
    padding: "0 1rem",
  },
  [theme.breakpoints.up("md")]: {
    height: "480px",
  },
}));

const Styledheading = styled("div")({
  fontFamily: "Noto Serif",
  fontSize: "40px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: "0.02em",
  color: "#0A253D",

  "@media (max-width: 600px)": {
    fontSize: "2rem",
    width: "100%",
    textAlign: "left",
    lineHeight: "38px",
    marginBottom: "30px",
  },
});
