import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import ContactUs from "./component/ContactUs";
import AboutUs from "./pages/AboutUs/AboutUs";
import DegreeCoursePage from "./pages/Course/DegreeCourse";
import CertificateCoursePage from "./pages/Course/CertficateCourse";
import DiplomaCoursePage from "./pages/Course/DiplomaCourse";
import ApplyPage from "./pages/Apply/ApplyPage";
import { useEffect, useState } from "react";
import "@fontsource/dm-sans";

function App() {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ overflow: "hidden" }}>
      <Router>
        <Routes>
          <Route element={<Home dopen={open} setdOpen={setOpen} />} path="/" />
          <Route element={<ApplyPage />} path="/apply" />
          <Route element={<ContactUs />} path="/contact" />
          <Route element={<DegreeCoursePage />} path="/degree-courses/:slug" />
          <Route
            element={<CertificateCoursePage />}
            path="/certificate-courses/:slug"
          />
          <Route
            element={<DiplomaCoursePage />}
            path="/diploma-courses/:slug"
          />
          <Route element={<AboutUs />} path="/about-us" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
