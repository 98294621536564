import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import certificate from "../../images/certificate.png";
import LazyLoad from "react-lazy-load";
import "../../style.css";
import { styled } from "@mui/material/styles";

export default function H2() {
  return (
    <Container container>
      <Grid
        sx={{ alignItems: "center", justifyContent: "center", display: "flex" }}
        sm={12}
        lg={5}
        xs={12}
      >
        <Title>
          We are an authorised Vocational Training Partner of School of
          Vocational Studies
        </Title>
      </Grid>
      <Grid sm={12} lg={7} xs={12}>
        <LazyLoad height={400} offset={700}>
          <StyledImage src={certificate} alt="om sterling certificate" />
        </LazyLoad>
      </Grid>
    </Container>
  );
}

const Title = styled(Typography)({
  fontFamily: "Noto Serif",
  fontSize: "35px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: "0.02em",
  color: "#0A253D",
  textAlign: "left",
  width: "90%",

  "@media (max-width: 600px)": {
    fontSize: "33px",
    textAlign: "center",
    lineHeight: "36px",
    width: "100%",
    margin: "1rem 0rem 3rem 0rem",
  },
});

const StyledImage = styled("img")({
  border: "1px solid #0A253D14 ",
  width: "90%",
  height: "400px",
  margin: "0px 0px 30px 20px",
  "@media (max-width: 600px)": {
    width: "100%",
    height: "300px",
    margin: "1rem 0.4rem",
  },
});

const Container = styled(Grid)({
  height: "fit-content",

  padding: "3rem 0 5rem 0",
  "@media (max-width: 600px)": {
    height: "fit-content",
    padding: 0,
  },
});
