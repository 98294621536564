import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";

const StatisticWrapper = styled("div")({
  height: 50,
  width: 70,
  backgroundColor: "#1E73BE0A",
  margin: 10,
  borderRadius: "10px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
});

const CountWrapper = styled("div")({
  fontSize: "56px",
  fontFamily: "Noto Serif",
  fontWeight: 700,
  lineHeight: "64px",
  letterSpacing: "0em",
  marginTop: 35,
  color: "#1E73BE",
});
const StatisticCounter = ({ startValue, endValue, duration = 1000 }) => {
  const [count, setCount] = useState(startValue);
  const countRef = useRef(null);

  const options = {
    rootMargin: "0px",
    threshold: 0.5,
  };

  const incrementCount = (startValue, endValue, duration) => {
    let startTime = null;

    const animateCount = (currentTime) => {
      if (startTime === null) {
        startTime = currentTime;
      }

      const progress = currentTime - startTime;
      const increment = Math.floor(
        (endValue - startValue) * (progress / duration)
      );
      const currentValue = startValue + increment;

      if (currentValue < endValue) {
        setCount(currentValue);
        requestAnimationFrame(animateCount);
      } else {
        setCount(endValue);
        countRef.current = null;
      }
    };

    requestAnimationFrame(animateCount);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        incrementCount(startValue, endValue, duration);
        observer.unobserve(entries[0].target);
      }
    }, options);

    if (countRef.current) {
      observer.observe(countRef.current);
    }

    return () => observer.disconnect();
  }, [options, startValue, endValue, duration]);

  return (
    <StatisticWrapper ref={countRef}>
      <CountWrapper>{count}</CountWrapper>
    </StatisticWrapper>
  );
};

export default StatisticCounter;
