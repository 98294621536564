import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import PrimaryButton from "./Primarybutton";
import SecondaryButton from "./Secondarybutton";
import logo from "../images/logo.jpg";
import { useNavigate } from "react-router-dom";
import TopBanner from "../pages/Home/TopBanner";
import {
  DegreeCourses,
  CertificateCourses,
  DiplomaCourses,
} from "../utils/courses";
import MobileMenu from "./MobileMenu";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorCert, setAnchorCert] = React.useState(null);
  const [anchorDip, setAnchorDip] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    setAnchorCert(null);
    setAnchorDip(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCertMenuOpen = (event) => {
    setAnchorCert(event.currentTarget);
    setAnchorElUser(null);
    setAnchorDip(null);
  };

  const handleCertMenuClose = () => {
    setAnchorCert(null);
  };

  const handleDipMenuOpen = (event) => {
    setAnchorDip(event.currentTarget);
    setAnchorElUser(null);
    setAnchorCert(null);
  };

  const handleDipMenuClose = () => {
    setAnchorDip(null);
  };
  return (
    <AppBar position="fixed" sx={{ background: "white" }}>
      <TopBanner />
      <Toolbar>
        <Container container>
          <LogoContainer
            item
            onClick={() => {
              navigate("/");
              scrollToTop();
            }}
          >
            <Logo src={logo} alt="Logo" />
            <Title component="div">
              SBIHM <br /> Kharagpur
            </Title>
          </LogoContainer>
          <NavItemsContainer item sm={4} md={6} lg={7}>
            <NavItem onClick={() => navigate("/about-us")}>about us</NavItem>

            <NavItem
              // onClick={handleOpenUserMenu}
              onMouseEnter={handleOpenUserMenu}
            >
              degree courses
            </NavItem>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              MenuListProps={{ onMouseLeave: handleCloseUserMenu }}
            >
              {DegreeCourses.map((course) => (
                <MenuItem
                  key={course.id}
                  onClick={() => navigate(`/degree-courses/${course.slug}`)}
                >
                  <Typography textAlign="center">{course.name}</Typography>
                </MenuItem>
              ))}
            </Menu>

            <NavItem
              // onClick={handleOpenUserMenu}
              onMouseEnter={handleDipMenuOpen}
            >
              diploma courses
            </NavItem>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorDip}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorDip)}
              onClose={handleDipMenuClose}
              MenuListProps={{ onMouseLeave: handleDipMenuClose }}
            >
              {DiplomaCourses.map((course) => (
                <MenuItem
                  key={course.id}
                  onClick={() => navigate(`/diploma-courses/${course.slug}`)}
                >
                  <Typography textAlign="center">{course.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <NavItem
              onClick={handleCertMenuOpen}
              onMouseEnter={handleCertMenuOpen}
            >
              certificate courses
            </NavItem>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorCert}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorCert)}
              onClose={handleCertMenuClose}
              MenuListProps={{ onMouseLeave: handleCertMenuClose }}
            >
              {CertificateCourses.map((course) => (
                <MenuItem
                  key={course.id}
                  onClick={() =>
                    navigate(`/certificate-courses/${course.slug}`)
                  }
                >
                  <Typography textAlign="center">{course.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </NavItemsContainer>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{
              display: { xs: "flex", md: "none" },
              ml: "auto",
            }}
            onClick={() => setOpen(!open)}
          >
            <MenuIcon
              style={{
                color: "black",
                fontSize: "35px",
                fontWeight: 900,
                borderRadius: "50%",
              }}
            />
          </IconButton>
          {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleMenuClose}>Degree Courses</MenuItem>
            <MenuItem onClick={handleMenuClose}>Diploma Courses</MenuItem>
            <MenuItem onClick={handleMenuClose}>Certificate Courses</MenuItem>
          </Menu> */}
          <MobileMenu open={open} setOpen={setOpen} />
          <Grid item md={0} lg={1}></Grid>
          <ButtonContainer item>
            <SecondaryButton size="medium" onClick={() => navigate("/contact")}>
              Contact Us
            </SecondaryButton>
            <PrimaryButton size="medium" onClick={() => navigate("/apply")}>
              {" "}
              Apply Now
            </PrimaryButton>
          </ButtonContainer>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

const BorderedDiv = styled("div")({
  display: "flex",
  border: "1px solid black",
});

const LogoContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "1rem",
  "& :hover": {
    cursor: "pointer",
  },

  "@media (max-width: 600px)": {
    paddingLeft: "0",
  },
});

const NavItemsContainer = styled(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  paddingRight: "20px",
  position: "relative", // make the container relative so we can position the dropdown menu absolutely
  // border: "1px solid black",
  "@media (max-width: 600px)": {
    display: "none",
  },
});

const DropdownMenu = styled("div")({
  position: "absolute",
  top: "100%",
  right: "0",
  zIndex: 1, // make sure the menu appears on top of other elements
  display: "none",
  "& ul": {
    listStyle: "none",
    margin: 0,
    padding: "8px",
    backgroundColor: "white",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
    minWidth: "150px",
    borderRadius: "5px",
  },
  "& ul li": {
    padding: "8px 12px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f48fb1",
      color: "white",
    },
  },
  "&:hover ul": {
    display: "block",
  },
});

const ButtonContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  "@media (max-width: 600px)": {
    display: "none",
  },
});

const Container = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const Logo = styled("img")({
  height: "50px",
  width: "50px",
  marginRight: "10px",

  "@media (max-width: 600px)": {
    height: "40px",
    width: "40px",
  },
});

const NavItem = styled(Button)({
  marginLeft: "10px",
  color: "black",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#f48fb1",
  },
});

const Title = styled(Typography)({
  color: "#4d627a",
  textTransform: "none",
  fontSize: "18px",
  fontWeight: "bold",
  lineHeight: "1",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#85a3c6",
  },

  "@media (max-width: 600px)": {
    fontSize: "16px",
  },
});

export default NavBar;
