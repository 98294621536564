import { Grid, Typography } from "@mui/material";
import { ReactComponent as Esteemed } from "../../images/esteemed.svg";
import { ReactComponent as Amenities } from "../../images/amenities.svg";
import { ReactComponent as Inclusivity } from "../../images/inclusivity.svg";
import { ReactComponent as Courses } from "../../images/courses.svg";
import { ReactComponent as Scholarship } from "../../images/scholarship.svg";
import { ReactComponent as Infrastructure } from "../../images/infrastructure.svg";
import { styled } from "@mui/material/styles";

export default function List() {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        backgroundColor: "#1E73BE14",
      }}
    >
      <Grid
        lg={4}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Esteemed
          // src="/Assets/esteemed.svg.svg"
          // fill="white"
          style={{
            height: 25,
            width: 25,
            borderRadius: 50,
            padding: 10,
            background: "#1E73BE",
            color: "white",
          }}
        />
        <div
          style={{
            fontFamily: "Noto Serif",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Esteemed
        </div>
        <StyledText
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#666666",
          }}
        >
          Our Institute has been providing long-term career opportunities in
          Hospitality Since 2014
        </StyledText>
      </Grid>
      <Grid
        lg={4}
        xs={12}
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Courses
          style={{
            height: 25,
            width: 25,
            borderRadius: 50,
            padding: 10,
            backgroundColor: "#1E73BE",
            color: "#FFFFFF",
          }}
        />
        <div
          style={{
            fontFamily: "Noto Serif",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Courses
        </div>
        <StyledText
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#666666",
          }}
        >
          Courses recognized by UGC (Ministry of HRD. Govt. of India) Approved
          State Govt. University.
        </StyledText>
      </Grid>
      <Grid
        lg={4}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Infrastructure
          style={{
            height: 25,
            width: 25,
            borderRadius: 50,
            padding: 10,
            backgroundColor: "#1E73BE",
            color: "#FFFFFF",
          }}
        />
        <div
          style={{
            fontFamily: "Noto Serif",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Infrastructure
        </div>
        <StyledText>
          A well set-up infrastructure which ultimately helps you to learn and
          perform better
        </StyledText>
      </Grid>

      <Grid
        lg={4}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Amenities
          // src="/Assets/amenities.svg.svg"
          style={{
            height: 25,
            width: 25,
            borderRadius: 50,
            padding: 10,
            backgroundColor: "#1E73BE",
            color: "#FFFFFF",
          }}
        />
        <div
          style={{
            fontFamily: "Noto Serif",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Modern Amenities
        </div>
        <StyledText
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#666666",
          }}
        >
          We’ve ensured proper amenities to students for their well-being along
          with quality education
        </StyledText>
      </Grid>
      <Grid
        lg={4}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Scholarship
          // src="/Assets/scholarship.svg.svg"
          style={{
            height: 25,
            width: 25,
            borderRadius: 50,
            padding: 10,
            backgroundColor: "#1E73BE",
            color: "#FFFFFF",
          }}
        />
        <div
          style={{
            fontFamily: "Noto Serif",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Scholarship
        </div>
        <StyledText
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#666666",
          }}
        >
          We offer financial aid is awarded to students for further education
        </StyledText>
      </Grid>
      <Grid
        lg={4}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Inclusivity
          style={{
            height: 25,
            width: 25,
            borderRadius: 50,
            padding: 10,
            backgroundColor: "#1E73BE",
            color: "#FFFFFF",
          }}
        />
        <div
          style={{
            fontFamily: "Noto Serif",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "32px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Inclusivity
        </div>
        <StyledText>
          We provide equal access to opportunities and resources for people who
          might otherwise be excluded
        </StyledText>
      </Grid>
    </Grid>
  );
}

const StyledText = styled(Typography)({
  fontFamily: "DM Sans",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  letterSpacing: "0.02em",
  textAlign: "center",
  color: "#666666",

  "@media(min-width: 600px)": {
    padding: "0px 50px",
  },
});
