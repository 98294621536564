import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function TopBanner() {
  var navigate = useNavigate();
  return (
    <Container>
      {/* <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1E73BE",
        }}
      > */}
      <Typography
        sx={{
          marginRight: "5px",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.02em",
          textAlign: "left",
        }}
      >
        {`Admission ${new Date().getFullYear()} - ${
          new Date().getFullYear() + 1
        }`}
      </Typography>
      <Typography>|</Typography>
      <Typography
        sx={{
          textDecoration: "underline",
          marginLeft: "5px",
          fontFamily: "DM Sans",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.02em",
          textAlign: "left",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/apply");
        }}
      >
        Apply Online
      </Typography>
    </Container>
  );
}

const Container = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#1E73BE",
  color: "white",
});
