import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Slide,
} from "@mui/material";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DegreeCourses,
  CertificateCourses,
  DiplomaCourses,
} from "../utils/courses";
import { useNavigate } from "react-router-dom";

const NavContainer = styled(Box)({
  width: "100%",
  height: "90vh",

  backgroundColor: "white",
  position: "fixed",
  top: 0,
  right: 0,
  zIndex: 1000,

  //   transition: "transform 3s ease-out",
  //   transform: (props) => (props.isOpen ? "translateX(0)" : "translateX(100%)"),
});

const NavList = styled(List)({
  padding: 0,
});

const NavListItem = styled(ListItemButton)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "2rem",
  paddingRight: "2rem",

  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
});

const NestedNavList = styled(List)({
  paddingLeft: "2rem",
});

const NavMenu = ({ open, setOpen, containerRef }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [degreeOpen, setDegreeOpen] = useState(false);
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [diplomaOpen, setDiplomaOpen] = useState(false);
  const history = useNavigate();

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  const handleSubItemClick = (route) => {
    history(route);
  };

  return (
    <React.Fragment>
      <Slide direction="down" in={open} timeout={400} unmountOnExit>
        <NavContainer isOpen={isOpen}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem 2rem",
            }}
          >
            <Typography sx={{ color: "black" }} variant="h5">
              SBIHM Kharagpur
            </Typography>
            <IconButton sx={{ color: "black" }} onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <NavList>
            <NavListItem>
              <ListItemText
                sx={{ color: "black" }}
                primary="About Us"
                onClick={() => handleSubItemClick(`/about-us`)}
              />
            </NavListItem>
            <NavListItem
              sx={{ backgroundColor: "rgba(30,115,190,1)" }}
              onClick={() => setDegreeOpen(!degreeOpen)}
            >
              <ListItemText sx={{ color: "white" }} primary="Degree Courses" />
              <ExpandMoreIcon sx={{ color: "white" }} />
            </NavListItem>
            <Collapse in={degreeOpen} timeout="auto" unmountOnExit>
              <NestedNavList component="div" disablePadding>
                {DegreeCourses.map((course) => (
                  <NavListItem
                    onClick={() =>
                      handleSubItemClick(`/degree-courses/${course.slug}`)
                    }
                  >
                    <ListItemText
                      sx={{ color: "black" }}
                      primary={course.name}
                    />
                  </NavListItem>
                ))}
              </NestedNavList>
            </Collapse>
            <NavListItem
              sx={{ backgroundColor: "rgba(30,115,190,1)" }}
              onClick={() => setDiplomaOpen(!diplomaOpen)}
            >
              <ListItemText sx={{ color: "white" }} primary="Diploma Courses" />
              <ExpandMoreIcon sx={{ color: "white" }} />
            </NavListItem>
            <Collapse in={diplomaOpen} timeout="auto" unmountOnExit>
              <NestedNavList component="div" disablePadding>
                {DiplomaCourses.map((course) => (
                  <NavListItem
                    onClick={() =>
                      handleSubItemClick(`/diploma-courses/${course.slug}`)
                    }
                  >
                    <ListItemText
                      sx={{ color: "black" }}
                      primary={course.name}
                    />
                  </NavListItem>
                ))}
              </NestedNavList>
            </Collapse>
            <NavListItem
              sx={{ backgroundColor: "rgba(30,115,190,1)" }}
              onClick={() => setCertificateOpen(!certificateOpen)}
            >
              <ListItemText
                sx={{ color: "white" }}
                primary="Certificate Courses"
              />
              <ExpandMoreIcon sx={{ color: "white" }} />
            </NavListItem>
            <Collapse in={certificateOpen} timeout="auto" unmountOnExit>
              <NestedNavList component="div" disablePadding>
                {CertificateCourses.map((course) => (
                  <NavListItem
                    onClick={() =>
                      handleSubItemClick(`/certificate-courses/${course.slug}`)
                    }
                  >
                    <ListItemText
                      sx={{ color: "black" }}
                      primary={course.name}
                    />
                  </NavListItem>
                ))}
              </NestedNavList>
            </Collapse>
            <NavListItem>
              <ListItemText
                sx={{ color: "black" }}
                primary="Contact"
                onClick={() => handleSubItemClick(`/contact`)}
              />
            </NavListItem>
            <NavListItem>
              <ListItemText
                sx={{ color: "black" }}
                primary="Apply now"
                onClick={() => handleSubItemClick(`/apply`)}
              />
            </NavListItem>
          </NavList>
          <NavFooter>
            Made with <FavoriteIcon style={{ margin: "0 10px" }} /> by
            <a href="https://www.xgenlab.tech">Xgenlab.Tech</a>{" "}
          </NavFooter>
        </NavContainer>
      </Slide>
    </React.Fragment>
  );
};

const NavFooter = styled("div")({
  display: "flex",
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "60px",
  color: "darkgrey",
  alignItems: "center",
  justifyContent: "center",

  "& a": {
    textDecoration: "none",
    padding: "0 10px",
  },
});

export default NavMenu;
