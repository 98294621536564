import { Divider, Grid, Button, Typography, Hidden } from "@mui/material";
import React, { Fragment, useEffect, useState, createRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import heroImage1 from "../../images/sbihm/HeroBanner-1.png";
import heroImage2 from "../../images/sbihm/HeroBanner-2.png";
import heroImage3 from "../../images/sbihm/HeroBanner-3.png";
import LazyLoad from "react-lazy-load";
import { styled } from "@mui/material/styles";

export default function Slider1() {
  const mySlider = createRef();

  var settings = {
    dots: true,
    autoplay: 300,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const images = [
    { image: heroImage1 },
    { image: heroImage2 },
    { image: heroImage3 },
  ];

  // const playSlide = () => {
  //   return images.map((item) => {
  //     return <StyledGrid url={item.image}>{/* <StyledImage /> */}</StyledGrid>;
  //   });
  // };

  const playSlide = () => {
    return images.map((item) => {
      return (
        <Fragment>
          <StyledGrid item xs={12} lg={12}>
            <StyledLazyLoad offsetVertical={500} height={500}>
              <StyledImage src={item.image} />
            </StyledLazyLoad>
          </StyledGrid>
        </Fragment>
      );
    });
  };

  return (
    <Grid container>
      <Hidden mdDown>
        <Grid item lg={12}>
          <Slider ref={mySlider} {...settings}>
            {playSlide()}
          </Slider>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid item lg={12}>
          <StyledImage src={heroImage3} />
        </Grid>
      </Hidden>
    </Grid>
  );
}

const StyledImage = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: "90vh",
    marginLeft: "-38%",
  },
  [theme.breakpoints.up("md")]: {
    height: 700,
    // width: "100vw",
  },
}));

const StyledGrid = styled(Grid)(({ theme, url }) => ({
  [theme.breakpoints.down("md")]: {
    height: "90vh",
    display: "grid",
    placeItems: "center",
  },
  [theme.breakpoints.up("md")]: {
    height: 600,
    width: "100%",
  },
}));

const StyledLazyLoad = styled(LazyLoad)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    height: 300,
  },
  [theme.breakpoints.up("md")]: {
    height: 600,
    // width: "100vw",
  },
}));
