import review1 from "../images/reviews/review1.jpg";
import review2 from "../images/reviews/review2.jpg";
import review3 from "../images/reviews/review3.jpg";
import review4 from "../images/reviews/review4.jpg";
import student1 from "../images/reviews/student1.jpeg";
import student2 from "../images/reviews/student2.jpeg";
import student3 from "../images/reviews/student3.jpeg";
import student4 from "../images/reviews/student4.jpeg";
import student5 from "../images/reviews/student5.jpeg";
import student6 from "../images/reviews/student6.jpeg";
import student7 from "../images/reviews/student7.jpeg";
import student8 from "../images/reviews/student8.jpeg";
import student9 from "../images/reviews/student9.jpeg";

export const PreviousStudentReviews = [
  // {
  //   id: 1,
  //   image: review1,
  //   name: "Rahul Sharma",
  //   course: "Diploma in Hotel Management",
  //   designation: "Chef",
  //   organistaion: "Novotel, Hyderabad.",
  //   review:
  //     "I had a great experience at this institute. The faculty is knowledgeable and supportive. The curriculum is well-structured and provides hands-on training. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
  //   duration: "2 years",
  //   year: "2018",
  // },
  // {
  //   id: 2,
  //   image: review3,
  //   name: "Shreya Banerjee",
  //   course: "Diploma in Hotel Management",
  //   designation: "Chef",
  //   organistaion: "The Raffles, The Palm , Dubai.",

  //   review:
  //     "This is a 100% trusted HM institute in Kharagpur. More than 100 students have been placed in 5-star hotels all over India and abroad. The study environment is excellent, and the teachers are well-behaved and knowledgeable. I am glad I chose this institute for my education.",
  //   duration: "2 years",
  //   year: "2010",
  // },
  // {
  //   id: 3,
  //   image: review2,
  //   name: "Amit Singh",
  //   course: "B Voc. in Hotel management",
  //   designation: "Chef",
  //   organistaion: "The Raffles, The Palm , Dubai.",
  //   review:
  //     "This institute has a great reputation for providing quality education and training in the hospitality industry. The faculty is experienced and knowledgeable, and the curriculum is designed to provide practical skills that are in high demand. I am happy with my decision to enroll in this institute.",
  //   duration: "2 years",
  //   year: "2021",
  // },
  // {
  //   id: 4,
  //   image: review4,
  //   name: "Suresh Sharma",
  //   course: "Adv. Diploma in Hotel Management",
  //   designation: "Front office Manager",
  //   organistaion: "White Castle, Bangalore",
  //   review:
  //     "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
  //   duration: "2 years",
  //   year: "2015",
  // },
  {
    id: 5,
    image: student1,
    name: "Susmita Bomzan",
    course: "B Voc. in Hotel management",
    designation: "",
    organistaion: "The Raffles, The Palm , Dubai.",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },

  {
    id: 6,
    image: student2,
    name: "Somir Maity",
    course: "B Voc. in Hotel management",
    designation: "",
    organistaion: "MOKSH Indian Restaurant, South Africa",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },
  {
    id: 7,
    image: student7,
    name: "Lokopriyo Bhattacharjee",
    course: "Adv. Diploma in Hotel Management",
    designation: "General Manager",
    organistaion: "Parkprime Durgapur",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },
  {
    id: 8,
    image: student6,
    name: "Shivsankar giri",
    course: "Diploma in Hotel Management",
    designation: "Assistant Cook",
    organistaion: "OMAN",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },
  {
    id: 9,
    image: student3,
    name: "Tejendra Gurung",
    course: "Adv. Diploma in Hotel Management",
    designation: "CHEF",
    organistaion: "Novotel, Cochin",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },
  {
    id: 10,
    image: student4,
    name: "Meghna Tamang",
    course: "B Voc. in Hotel management",
    designation: "Guest service officer",
    organistaion: "Hyaat, Hyderabad",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },
  {
    id: 11,
    image: student5,
    name: "Rajkumar jana",
    course: "B Voc. in Hotel management",
    designation: "F & B executive",
    organistaion: "The Royal Retreat, Resort & Spa, Udaipur",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2015",
  },
  {
    id: 12,
    image: student8,
    name: " Tutu Boro",
    course: "Diploma in Hotel management",
    designation: "Chef",
    organistaion: "Dyaasko Oley allo Commi 1",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2018",
  },
  {
    id: 13,
    image: student9,
    name: "Pabitra Maity",
    course: "B Voc. in Hotel management",
    designation: "Captain",
    organistaion: "The FERN, Goregaon.",
    review:
      "I am grateful for the opportunity to study at this institute. The faculty is supportive and knowledgeable, and the curriculum is well-structured. I have gained valuable skills and knowledge that will help me succeed in the hospitality industry. I would highly recommend this institute to anyone interested in pursuing a career in hotel management.",
    duration: "2 years",
    year: "2019",
  },
];

export const contactEmail = "info@sbihmkgp.com";
