import React, { useState } from "react";
import { Grid, Typography, Hidden, TextField, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { blueGrey } from "@mui/material/colors";
import {
  DiplomaCourses,
  DegreeCourses,
  CertificateCourses,
  indianStates,
} from "../../utils/courses";
import "../../style.css";
import { contactEmail } from "../../utils/reviews";
import Loader from "../../component/Loader";

export default function ApplyM() {
  const [openTenth, setOpenTenth] = useState(false);
  const [openTwelth, setOpenTwelth] = useState(false);
  const [openDiploma, setOpenDiploma] = useState(false);
  const [openGraduation, setOpenGraduation] = useState(false);
  const [openPostGraduation, setOpenPostGraduation] = useState(false);
  const [loading, setLoading] = useState(false);

  const courses = [...DiplomaCourses, ...DegreeCourses, ...CertificateCourses];

  const [personalInfo, setPersonalInformation] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    bloodGroup: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    sex: "",
    fatherName: "",
    motherName: "",
    guardianMobileNumber: "",
  });

  const [err, setErr] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const checkError = () => {
    if (personalInfo.fullName === "") {
      setErr((err) => ({ ...err, name: true }));
      return true;
    }
    if (personalInfo.email === "") {
      setErr((err) => ({ ...err, email: true }));
      return true;
    }
    if (personalInfo.phone === "") {
      setErr((err) => ({ ...err, phone: true }));
      return true;
    }
    return false;
  };

  const [languagesKnown, setLanguagesKnown] = useState([
    {
      lName: "",
      read: false,
      write: false,
      speak: false,
    },
    {
      lName: "",
      read: false,
      write: false,
      speak: false,
    },
    {
      lName: "",
      read: false,
      write: false,
      speak: false,
    },
  ]);

  const [language1, setLanguage1] = useState({
    lName: "",
    read: false,
    write: false,
    speak: false,
  });
  const [language2, setLanguage2] = useState({
    lName: "",
    read: false,
    write: false,
    speak: false,
  });
  const [language3, setLanguage3] = useState({
    lName: "",
    read: false,
    write: false,
    speak: false,
  });

  const [education, setEducation] = useState([
    {
      selected: false,
      name: "Secondary Examination (10th)",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Higher Secondary Examination (12th)",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Diploma",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Graduate Degree",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Post - Graduation Degree",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
  ]);

  React.useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);

  const handlePersonalInfoChange = (event) => {
    setPersonalInformation({
      ...personalInfo,
      [event.target.name]: event.target.value,
    });
  };

  const handleLanguagesChange = (event, index) => {
    let temp = languagesKnown;
    temp[index][event.target.name] = event.target.checked || event.target.value;
    setLanguagesKnown(() => temp);
    console.log(languagesKnown);
  };

  const handleEducationChange = (event, index) => {
    let temp = [...education];
    temp[index][event.target.name] = event.target.value;
    setEducation(temp);
  };

  const handleSubmit = () => {
    if (checkError()) return;
    setLoading(true);

    let payload = {
      ...personalInfo,
      language1: `Name : ${language1.lname}, ||  read :  ${language1.read}, ||  write :  ${language1.write},  ||  speak :  ${language1.speak}`,
      language2: `Name : ${language2.lname},  ||  read :  ${language2.read}, ||  write :  ${language2.write},  ||  speak :  ${language2.speak}`,
      language3: `Name : ${language3.lname},  ||  read :  ${language3.read}, ||  write :  ${language3.write},  ||  speak :  ${language3.speak}`,

      [education[0]
        .name]: `Subject :  ${education[0].subject} ||  Board :  ${education[0].board}  ||  Year of passing :  ${education[0].year}  ||  Marks :  ${education[0].marks}`,
      [education[1]
        .name]: `Subject :  ${education[1].subject} ||  Board :  ${education[1].board} ||   Year of passing :  ${education[1].year}  ||  Marks :  ${education[1].marks}`,
      [education[2]
        .name]: `Subject :  ${education[2].subject} ||  Board :  ${education[2].board} ||  Year of passing :  ${education[2].year}  ||  Marks :  ${education[2].marks}`,
      [education[3]
        .name]: `Subject :  ${education[3].subject}  || Board :  ${education[3].board}  ||  Year of passing :  ${education[3].year}  ||  Marks :  ${education[3].marks}`,
      [education[4]
        .name]: `Subject :  ${education[4].subject} ||  Board :  ${education[4].board}  ||  Year of passing :  ${education[4].year}  ||  Marks :  ${education[4].marks}`,
    };

    fetch("https://formsubmit.co/ajax/" + contactEmail, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const fillCategoryDropDown = () => {
    return courses.map((item) => {
      return (
        <MenuItem id={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      );
    });
  };

  return (
    <Grid container>
      <Loader loading={loading} />
      <Hidden smUp>
        <Grid item xs={12} sx={{ bgcolor: "#FFFFF" }}>
          <div className="apply1">
            <Typography
              sx={{
                fontFamily: "Noto Serif",
                fontSize: "40px",
                fontWeight: 600,
                lineHeight: "48px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#523D1C",
              }}
            >
              Apply Now
            </Typography>
          </div>
          <div className="apply1">
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "36px",
                letterSpacing: "0.02em",
                textAlign: "center",
                color: "#666666",
              }}
            >
              {`Academic Year ${new Date().getFullYear()} - ${
                new Date().getFullYear() + 1
              }`}
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#1E73BE14",
            margin: "10px",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28px",
              letterSpacing: "0.02em",
              textAlign: "center",
              color: "#000000",
              margin: "10px 0",
            }}
          >
            Application Form
          </Typography>
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "28px",
              letterSpacing: "0.02em",
              textAlign: "center",
              color: "#666666",
              margin: "10px 0",
            }}
          >
            Fill out the application form and our team will get back to you
          </Typography>

          <Grid
            sx={{
              bgcolor: "#FFFFFF",
              width: "100%",
              borderRadius: "10px",
              marginTop: "15px",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "40px",
                  backgroundColor: "#1E73BE",
                  fontSize: 20,
                  fontWeight: 800,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                1
              </div>
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                Personal Information
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Courses</InputLabel>
                <Select
                  // value={categoryId}
                  // label="Category Name"
                  // onChange={(event) => setCategoryId(event.target.value)}
                  // InputProps={{ style: { borderRadius: 50 } }}
                  style={{ borderRadius: 50 }}
                >
                  {fillCategoryDropDown()}
                </Select>
              </FormControl>
              <TextField
                size="small"
                label="Applicant’s Full Name"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="fullName"
                value={personalInfo.fullName}
                onChange={handlePersonalInfoChange}
                required
                error={err.name}
              />
              <TextField
                size="small"
                label="Applicant’s Mobile No."
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="phone"
                value={personalInfo.phone}
                onChange={handlePersonalInfoChange}
                required
                error={err.phone}
              />
              <TextField
                size="small"
                label="Applicant’s Email"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="email"
                value={personalInfo.email}
                onChange={handlePersonalInfoChange}
                required
                error={err.email}
              />
              <TextField
                size="small"
                label="Communicating Address"
                multiline
                rows={3}
                maxRows={5}
                fullWidth
                InputProps={{ style: { borderRadius: 20 } }}
                style={{ marginTop: 10 }}
                name="address"
                value={personalInfo.address}
                onChange={handlePersonalInfoChange}
              />
              <FormControl fullWidth size="small" style={{ marginTop: 10 }}>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  // value={categoryId}
                  // label="Category Name"
                  // onChange={(event) => setCategoryId(event.target.value)}
                  // InputProps={{ style: { borderRadius: 50 } }}
                  style={{ borderRadius: 50 }}
                >
                  {indianStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                size="small"
                label="District"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="district"
                value={personalInfo.district}
                onChange={handlePersonalInfoChange}
              />
              <TextField
                size="small"
                label="Pincode"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="pincode"
                value={personalInfo.pincode}
                onChange={handlePersonalInfoChange}
              />
              <TextField
                placeholder="Date Of Birth"
                variant="outlined"
                fullWidth
                size="small"
                style={{ marginTop: 10 }}
                InputProps={{
                  style: { borderRadius: 50 },
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarMonthIcon />
                    </InputAdornment>
                  ),
                }}
                name="dob"
                value={personalInfo.dob}
                onChange={handlePersonalInfoChange}
                type="date"
              />
              <TextField
                size="small"
                label="Blood Group"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="bloodGroup"
                value={personalInfo.bloodGroup}
                onChange={handlePersonalInfoChange}
              />
              <TextField
                variant="outlined"
                placeholder="Father’s / Guardian’s Name"
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 50,
                  },
                }}
                style={{ marginTop: "15px" }}
                name="fatherName"
                value={personalInfo.fatherName}
                onChange={handlePersonalInfoChange}
              />
              <TextField
                variant="outlined"
                placeholder="Mother’s Name"
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: 50,
                  },
                }}
                style={{ marginTop: "15px" }}
                name="motherName"
                value={personalInfo.motherName}
                onChange={handlePersonalInfoChange}
              />
              <TextField
                size="small"
                variant="outlined"
                placeholder="Guardian’s Mobile No."
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 50,
                  },
                }}
                style={{ marginTop: "10px" }}
                name="guardianMobileNumber"
                value={personalInfo.guardianMobileNumber}
                onChange={handlePersonalInfoChange}
              />
            </div>

            <div style={{ marginTop: "15px", marginLeft: "15px" }}>
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  // textAlign: "left",
                  color: "#666666",
                  marginTop: "10px",
                }}
              >
                Sex :
              </div>
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="male"
                    row
                    name="sex"
                    value={personalInfo.sex}
                    onChange={(e) => handlePersonalInfoChange(e)}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div style={{ marginTop: "15px", marginLeft: "15px" }}>
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  // textAlign: "left",
                  color: "#666666",
                  marginTop: "10px",
                }}
              >
                Caste Category :
              </div>
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="UR"
                    row
                    name="sex"
                    value={personalInfo.sex}
                    onChange={(e) => handlePersonalInfoChange(e)}
                  >
                    <FormControlLabel
                      value="UR"
                      control={<Radio />}
                      label="UR"
                    />
                    <FormControlLabel
                      value="OBC"
                      control={<Radio />}
                      label="OBC"
                    />
                    <FormControlLabel
                      value="SC"
                      control={<Radio />}
                      label="SC"
                    />
                    <FormControlLabel
                      value="ST"
                      control={<Radio />}
                      label="ST"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div style={{ marginTop: "15px", marginLeft: "15px" }}>
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  // textAlign: "left",
                  color: "#666666",
                  marginTop: "10px",
                }}
              >
                Language Known :
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "15px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                  }}
                >
                  1
                </div>
                <div
                  style={{
                    marginLeft: 50,
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 50,
                      },
                    }}
                    name="lName"
                    value={language1.lName}
                    onChange={(e) =>
                      setLanguage1((prev) => ({
                        ...prev,
                        [e.target.name]: [e.target.value],
                      }))
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                        name="read"
                        checked={language1.read}
                        onChange={(e) =>
                          setLanguage1((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.checked,
                          }))
                        }
                      />
                    }
                    label="Read"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Write"
                    name="write"
                    checked={language1.write}
                    onChange={(e) =>
                      setLanguage1((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Speak"
                    name="speak"
                    checked={language1.speak}
                    onChange={(e) =>
                      setLanguage1((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                </FormGroup>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "15px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                  }}
                >
                  2
                </div>
                <div
                  style={{
                    marginLeft: 50,
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 50,
                      },
                    }}
                    name="lName"
                    value={language2.lName}
                    onChange={(e) =>
                      setLanguage2((prev) => ({
                        ...prev,
                        [e.target.name]: [e.target.value],
                      }))
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Read"
                    name="read"
                    checked={language2.read}
                    onChange={(e) =>
                      setLanguage2((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Write"
                    name="write"
                    checked={language2.write}
                    onChange={(e) =>
                      setLanguage2((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Speak"
                    name="speak"
                    checked={language2.speak}
                    onChange={(e) =>
                      setLanguage2((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                </FormGroup>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "15px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                  }}
                >
                  3
                </div>
                <div
                  style={{
                    marginLeft: 50,
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 50,
                      },
                    }}
                    name="lName"
                    value={language3.lName}
                    onChange={(e) =>
                      setLanguage3((prev) => ({
                        ...prev,
                        [e.target.name]: [e.target.value],
                      }))
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Read"
                    name="read"
                    checked={language3.read}
                    onChange={(e) =>
                      setLanguage3((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Write"
                    name="write"
                    checked={language3.write}
                    onChange={(e) =>
                      setLanguage3((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: blueGrey[900],
                          "&.Mui-checked": {
                            color: blueGrey[900],
                          },
                        }}
                      />
                    }
                    label="Speak"
                    name="speak"
                    checked={language3.speak}
                    onChange={(e) =>
                      setLanguage3((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.checked,
                      }))
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </Grid>
          <div
            style={{
              height: "2px",
              width: "100%",
              backgroundColor: "#1E73BE14",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          ></div>
          <Grid
            sx={{
              bgcolor: "#FFFFFF",
              width: "100%",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "40px",
                  backgroundColor: "#1E73BE",
                  fontSize: 20,
                  fontWeight: 800,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                2
              </div>

              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                Educational Qualifications
              </div>
            </div>

            <div
              style={{
                fontFamily: "DM Sans",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0.02em",
                alignItems: "center",
                color: "#666666",
                marginLeft: "50px",
              }}
            >
              Check the examinations you've completed/graduated
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                sx={{
                  color: blueGrey[900],
                  "&.Mui-checked": {
                    color: blueGrey[900],
                  },
                }}
                value={openTenth}
                onChange={() => setOpenTenth(!openTenth)}
              />
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  color: "#666666",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Secondary Examination (10th)
              </div>
            </div>
            {openTenth && (
              <div>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  name="subject"
                  value={education[0].subject}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="board"
                  value={education[0].board}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="year"
                  value={education[0].year}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="marks"
                  value={education[0].marks}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
              </div>
            )}

            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                sx={{
                  color: blueGrey[900],
                  "&.Mui-checked": {
                    color: blueGrey[900],
                  },
                }}
                value={openTwelth}
                onChange={() => setOpenTwelth(!openTwelth)}
              />
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  color: "#666666",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Higher Secondary Examination (12th)
              </div>
            </div>
            {openTwelth && (
              <div>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  name="subject"
                  value={education[1].subject}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="board"
                  value={education[1].board}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="year"
                  value={education[1].year}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="marks"
                  value={education[1].marks}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
              </div>
            )}

            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                sx={{
                  color: blueGrey[900],
                  "&.Mui-checked": {
                    color: blueGrey[900],
                  },
                }}
                value={openDiploma}
                onChange={() => setOpenDiploma(!openDiploma)}
              />
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  color: "#666666",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Diploma
              </div>
            </div>
            {openDiploma && (
              <div>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  name="subject"
                  value={education[2].subject}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="board"
                  value={education[2].board}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="year"
                  value={education[2].year}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="marks"
                  value={education[2].marks}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
              </div>
            )}

            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                sx={{
                  color: blueGrey[900],
                  "&.Mui-checked": {
                    color: blueGrey[900],
                  },
                }}
                value={openGraduation}
                onChange={() => setOpenGraduation(!openGraduation)}
              />
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  color: "#666666",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Graduate Degree
              </div>
            </div>
            {openGraduation && (
              <div>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  name="subject"
                  value={education[3].subject}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="board"
                  value={education[3].board}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="year"
                  value={education[3].year}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                />
              </div>
            )}

            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                sx={{
                  color: blueGrey[900],
                  "&.Mui-checked": {
                    color: blueGrey[900],
                  },
                }}
                value={openPostGraduation}
                onChange={() => setOpenPostGraduation(!openPostGraduation)}
              />
              <div
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.02em",
                  color: "#666666",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Post - Graduation Degree
              </div>
            </div>
            {openPostGraduation && (
              <div>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  name="subject"
                  value={education[4].subject}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="board"
                  value={education[4].board}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="year"
                  value={education[4].year}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                    },
                  }}
                  style={{ marginTop: "10px" }}
                  name="marks"
                  value={education[4].marks}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
              </div>
            )}
          </Grid>
          <Grid style={{ marginTop: "20px" }}>
            <Button
              sx={{ borderRadius: 15 }}
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
