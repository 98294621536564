import course1 from "../images/courses/course1.jpg";
import course2 from "../images/courses/course2.jpg";
import course3 from "../images/courses/course3.jpg";
import cert1 from "../images/courses/cert1.jpg";
import cert2 from "../images/courses/cert2.webp";
import cert3 from "../images/courses/cert3.jpg";
import cert4 from "../images/courses/cert4.jpeg";
import dip1 from "../images/courses/dip1.jpg";

import cp1 from "../images/courses/cp1.jpg";
import cp2 from "../images/courses/cp2.jpg";
import cp3 from "../images/courses/cp3.jpg";
import cp4 from "../images/courses/cp4.jpg";

export const DegreeCourses = [
  {
    id: 1,
    name: "Diploma in Hotel Management",
    slug: "diploma-in-hotel-management",
    duration: "1 year",
    eligibility: "10+2 (Higher Secondary)",
    industrialTraining: "6 months",
    placement: "India and Abroad",
    image: course1,
    pageImage: cp1,
    data: [
      {
        name: "Semester 1",
        subjects: [
          "English-1",
          "Food Production Foundation-1",
          "Food & BeverageService Foundation-1",
          "Environmental Studies",
          "English 1 Lab",
          "Food Production Foundation 1 Lab",
          "Food & Beverage Service-1 Lab",
          "Project-I (Organization of Food Festival as a part of activity based learning)",
        ],
      },

      {
        name: "Semester 2",
        subjects: [
          "Housekeeping Foundation-I",
          "English-II",
          "Front Office Foundation-l",
          "House Keeping Foundation-1 Lab",
          "English-II Lab",
          "Front Office Foundation -I Lab",
          "Project-II (Project related to Event Planning Meetings Incentives,Events & Conferences)",
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Advanced diploma in hotel Management",
    slug: "advanced-diploma-in-hotel-management",
    duration: "2 years",
    eligibility: "10+2(Higher Secondary)",
    industrialTraining: "6 months",
    placement: "Indian & Abroad",
    image: course2,
    pageImage: cp2,
    data: [
      {
        name: "Semester 1",
        subjects: [
          "English-1",
          "Food Production Foundation-1",
          "Food & BeverageService Foundation-1",
          "Environmental Studies",
          "English 1 Lab",
          "Food Production Foundation 1 Lab",
          "Food & Beverage Service-1 Lab",
          "Project-I (Organization of Food Festival as a part of activity based learning)",
        ],
      },

      {
        name: "Semester 2",
        subjects: [
          "Housekeeping Foundation-I",
          "English-II",
          "Front Office Foundation-l",
          "House Keeping Foundation-1 Lab",
          "English-II Lab",
          "Front Office Foundation -I Lab",
          "Project-II (Project related to Event Planning Meetings Incentives,Events & Conferences)",
        ],
      },
      {
        name: "Semester 3",
        subjects: [
          "Application of Computers-I",
          "Food Production-II",
          "Food & Beverage Service-I",
          "Application of Computers-l Lab",
          "Food Production-II Lab",
          "Food & Beverage Service-II Lab",
          "Project-III (Food Festival)",
        ],
      },
      {
        name: "Semester 4",
        subjects: [
          "Accounting skills for hospitality",
          "Front Office - II",
          "Personlaity skills for Hospitality Lab",
          "Front Office -II Lab",
          "Housekeeping II Lab",
          "Project-IV (Theme Lunch)",
        ],
      },
    ],
  },
  {
    id: 3,
    name: "B.Voc in Hotel Management",
    slug: "bvoc-in-hotel-management",
    duration: "3 years",
    eligibility: "10+2(Higher Secondary)",
    industrialTraining: "3 months",
    placement: "Indian & Abroad",
    image: course3,
    pageImage: cp3,
    data: [
      {
        name: "Semester 1",
        subjects: [
          "English-1",
          "Food Production Foundation-1",
          "Food & BeverageService Foundation-1",
          "Environmental Studies",
          "English 1 Lab",
          "Food Production Foundation 1 Lab",
          "Food & Beverage Service-1 Lab",
          "Project-I (Organization of Food Festival as a part of activity based learning)",
        ],
      },

      {
        name: "Semester 2",
        subjects: [
          "Housekeeping Foundation-I",
          "English-II",
          "Front Office Foundation-l",
          "House Keeping Foundation-1 Lab",
          "English-II Lab",
          "Front Office Foundation -I Lab",
          "Project-II (Project related to Event Planning Meetings Incentives,Events & Conferences)",
        ],
      },
      {
        name: "Semester 3",
        subjects: [
          "Application of Computers-I",
          "Food Production-II",
          "Food & Beverage Service-I",
          "Application of Computers-l Lab",
          "Food Production-II Lab",
          "Food & Beverage Service-II Lab",
          "Project-III (Food Festival)",
        ],
      },
      {
        name: "Semester 4",
        subjects: [
          "Accounting skills for hospitality",
          "Front Office - II",
          "Personlaity skills for Hospitality Lab",
          "Front Office -II Lab",
          "Housekeeping II Lab",
          "Project-IV (Theme Lunch)",
        ],
      },
      {
        name: "Semester 5",
        subjects: [
          "Front Office - III",
          "Regioinal Cusines",
          "Front Office -III Lab",
          "Regioinal Cusines Lab",
          "HouseKeeping management Lab",
          "Minor Project (Prepare and submit report on tasks related to front office department of hotel)",
        ],
      },
      {
        name: "Semester 6",
        subjects: [
          "Food and Beverage Service Management",
          "Hospilatiy Marketing",
          "Foreign Language French",
          "Food and Beverage Service Management Lab",
          "Hospitality Marketing Lab",
          "Foreign Language French Lab",
          "Major Project (Study of different departments of hotels and submission of tour reports)",
        ],
      },
    ],
  },
];

export const DiplomaCourses = [
  {
    id: 3,
    name: "Diploma in Hotel Management",
    slug: "diploma-in-hotel-management",
    duration: "3 years",
    eligibility: "10th pass (Secondary)",
    industrialTraining: "3 months",
    placement: "Indian & Abroad",
    image: dip1,
    pageImage: cp3,
    data: [
      {
        name: "Semester 1",
        subjects: [
          "Foundation Course in Food Production - I",
          "Foundation Course in Food and Beverage Service -I",
          "Foundation Course in Front Office - I",
          "Foundation Course in Accommodation Operations - I",
          "Principle of Food Science",
          "Computer  Applications",
          "Foundation Course in Food Production - I  Lab",
          "Foundation Course in Food and Beverage Service -I Lab",
          "Foundation Course in Front Office - I Lab",
          "Foundation Course in Accommodation Operations - I Lab",
          "Computer  Applications Lab",
        ],
      },

      {
        name: "Semester 2",
        subjects: [
          "Foundation Course in Food Production - II",
          "Foundation Course in Food and Beverage Service -II",
          "Foundation Course in Front Office - II",
          "Foundation Course in Accommodation Operations - II",
          "Principle of Food Science",
          "Accountancy",
          "Communication",
          "Foundation Course in Food Production - II  Lab",
          "Foundation Course in Food and Beverage Service -II Lab",
          "Foundation Course in Front Office - II Lab",
          "Foundation Course in Accommodation Operations - II Lab",
          "Computer  Applications Lab",
        ],
      },
      {
        name: "Semester 3",
        subjects: [
          "Food Production-I",
          "Food & Beverage Service-I",
          "Room Division Management - I",
          "hotel French",
          "Facility Planning",
          "Food Production-I Lab",
          "Food & Beverage Service-I Lab",
          "Room Division Management - I Lab",
        ],
      },
      {
        name: "Semester 4",
        subjects: [
          "Food Production-II",
          "Food & Beverage Service-II",
          "Room Division Management - II",
          "Priciples of Management",
          "Travel and Tourism",
          "Food Production-II Lab",
          "Food & Beverage Service-II Lab",
          "Room Division Management - II Lab",
        ],
      },
      { name: "Semester 5", subjects: ["Industrial Training ( 22 Weeks)"] },
      {
        name: "Semester 6",
        subjects: [
          "Advanced Food Production",
          "Advanced Food & Beverage Service",
          "Advanced Room Division Management ",
          "Priciples of Management",
          "Travel and Tourism",
          "Advanced Food Production Lab",
          "Advanced Food & Beverage Service Lab",
          "Advanced Room Division Management  Lab",
        ],
      },
    ],
  },
];

export const CertificateCourses = [
  {
    id: 1,
    name: "Certificate in Front Office operation",
    slug: "certificate-in-front-office-operations",
    duration: "6 months",
    eligibility: "10th pass (Secondary)",
    industrialTraining: "Yes",
    placement: "Indian & Abroad",
    image: cert1,
    pageImage: cp3,
    keyForImage: "certificate-in-hotel-operations-image",
    data: [
      {
        name: "First Year",
        subjects: [
          "Introduction to Hospitality Industry",
          "Front Office Operations",
          "Housekeeping Operations",
          "Food Production Basics",
          "Food and Beverage Service Basics",
        ],
      },
      {
        name: "Second Year",
        subjects: [
          "Advanced Front Office Operations",
          "Advanced Housekeeping Operations",
          "Advanced Food Production",
          "Advanced Food and Beverage Service",
          "Hygiene and Sanitation",
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Certificate in Food Production",
    slug: "certificate-in-food-production",
    duration: "6 months",
    eligibility: "10th pass (Secondary)",
    industrialTraining: "Yes",
    placement: "Indian & Abroad",
    image: cert3,
    pageImage: cp2,
    keyForImage: "certificate-in-food-production-image",
    data: [
      {
        name: "First Year",
        subjects: [
          "Introduction to Hospitality Industry",
          "Food Production Basics",
          "Bakery and Confectionery Basics",
          "Hygiene and Sanitation",
          "Food and Beverage Service Basics",
        ],
      },
      {
        name: "Second Year",
        subjects: [
          "Advanced Food Production",
          "Advanced Bakery and Confectionery",
          "Food and Beverage Cost Control",
          "Menu Planning and Design",
          "International Cuisines",
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Certificate in Food and Beverage Service",
    slug: "certificate-in-food-and-beverage-service",
    duration: "6 months",
    eligibility: "10th pass (Secondary)",
    industrialTraining: "Yes",
    placement: "Indian & Abroad",
    image: cert2,
    pageImage: cp1,
    keyForImage: "certificate-in-food-and-beverage-service-image",
    data: [
      {
        name: "First Year",
        subjects: [
          "Introduction to Hospitality Industry",
          "Food and Beverage Service Basics",
          "Bar and Beverage Management",
          "Hygiene and Sanitation",
          "Front Office Operations",
        ],
      },
      {
        name: "Second Year",
        subjects: [
          "Advanced Food and Beverage Service",
          "Advanced Bar and Beverage Management",
          "Wine and Beverage Studies",
          "Service Salesmanship",
          "Hospitality Marketing",
        ],
      },
    ],
  },
  {
    id: 4,
    name: "Certificate in Housekeeping Operations",
    slug: "certificate-in-housekeeping-operations",
    duration: "6 months",
    eligibility: "10th pass (Secondary)",
    industrialTraining: "Yes",
    placement: "Indian & Abroad",
    image: cert4,
    pageImage: cp4,
    keyForImage: "certificate-in-housekeeping-operations-image",
    data: [
      {
        name: "First Year",
        subjects: [
          "Introduction to Hospitality Industry",
          "Housekeeping Operations Basics",
          "Front Office Operations",
          "Hygiene and Sanitation",
          "Food and Beverage Service Basics",
        ],
      },
      {
        name: "Second Year",
        subjects: [
          "Advanced Housekeeping Operations",
          "Laundry Management",
          "Pest Control Management",
          "Event Management",
          "Facility Management",
        ],
      },
    ],
  },
];

export const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
