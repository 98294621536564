import * as React from "react";
import { Grid, Typography, TextField, Button, Hidden } from "@mui/material";
import NavBar from "./Navbar";
import H3 from "../pages/Home/H3";
import Footer from "./Footer";
import Map1 from "./Map1";
import FlashScreen from "./splash2";
export default function ContactUs() {
  React.useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  return (
    <Grid container>
      <FlashScreen view />
      <Grid xs={12} lg={12} sm={12}>
        <NavBar />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 15 }}>
        <Typography
          style={{
            fontFamily: "Noto Serif",
            fontSize: "40px",
            fontWeight: 600,
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "black",
            marginTop: 19,
          }}
        >
          Contact us
        </Typography>
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: 500,
            lineHeight: "36px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#666666",
            marginTop: 10,
          }}
        >
          Reach out to us if you have any queries
        </Typography>
      </Grid>

      <Grid item sx={{ padding: 5 }} lg={12}>
        <Map1 noBack />
      </Grid>

      <Grid item xs={12}>
        <H3 />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5 }}>
        <Footer />
      </Grid>
    </Grid>
  );
}
