import React from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import { Button, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import blue from "../images/blue.png";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
const StyledCard = styled(Card)(({ theme }) => ({
  width: 200,
  height: 200,
  perspective: "1000px",
}));

const StyledOverlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: 15,
  background:
    "linear-gradient(to top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.1) 100%)",
});

const StyledFrontSide = styled(FrontSide)(({ theme, imageUrl }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: `url(${imageUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "absolute",

  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: 15,
}));

const StyledBackSide = styled(BackSide)(({ theme, imageUrl }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${blue})`,

  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  borderRadius: 10,
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  fontSize: 24,
  marginBottom: theme.spacing(2),
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: 16,
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  color: "#0D47A1",
  borderRadius: 20,
  "&:hover": {
    backgroundColor: "white",
  },
}));

const TitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2),
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  //   border: "1px solid white",
  color: "white",
  position: "absolute",

  bottom: 0,
}));

//responsive styled image
const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: 10,
}));

//styled flippy card
const StyledFlippy = styled(Flippy)(({ theme }) => ({
  width: "95%",
  height: 270,
  objectFit: "cover",
  marginTop: theme.spacing(2),
  position: "relative",
}));

const CardFlip = ({ image, name, duration, eligibility, slug, vertical }) => {
  var navigate = useNavigate();

  const onbuttonClick = () => {
    if (!vertical) navigate("/degree-courses/" + slug);
    else navigate("/certificate-courses/" + slug);
  };

  return (
    <StyledFlippy
      flipOnHover={true}
      flipDirection={vertical ? "vertical" : "horizontal"}
    >
      <LazyLoad height={270} offsetVertical={300}>
        <StyledFrontSide imageUrl={image}>
          {/* <StyledCard>
          <CardContent></CardContent>
        </StyledCard> */}
          <StyledOverlay>
            <TitleContainer>
              <Typography
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                  textAlign: "center",
                  color: "#FFFFFF",
                  margin: "1rem 3rem",
                }}
              >
                {name}
              </Typography>
            </TitleContainer>
          </StyledOverlay>
        </StyledFrontSide>
      </LazyLoad>
      <LazyLoad height={270} offsetVertical={300}>
        <StyledBackSide>
          <Grid
            lg={3}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              margin: 3,
            }}
          >
            {/* <StyledImage src={blue} alt="blue" /> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: 10,
              }}
            >
              <Typography
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                  textAlign: "center",
                  color: "#FFFFFF",
                  // marginTop: "-105%",
                }}
              >
                {name}
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: 1000,
                  color: "white",
                }}
              >
                |
              </Typography>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <div style={{ marginRight: "20px" }}>
                  <div
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    Durations
                  </div>
                  <div
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    {duration}
                  </div>
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <div
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    Eligibility
                  </div>
                  <div
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      letterSpacing: "0.02em",
                      textAlign: "center",
                      color: "#FFFFFF",
                    }}
                  >
                    {eligibility}
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                style={{
                  padding: 10,
                  paddingRight: 30,
                  paddingLeft: 30,
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  marginTop: "20px",
                  color: "#0D47A1",
                  textTransform: "none",
                }}
                onClick={onbuttonClick}
              >
                Learn More
              </Button>
            </div>
          </Grid>
        </StyledBackSide>
      </LazyLoad>
    </StyledFlippy>
  );
};

export default CardFlip;
