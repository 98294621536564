import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme, size }) => ({
  borderRadius: "50px",
  textTransform: "none",
  margin: "10px",
  fontWeight: "regular",

  fontSize:
    size === "small"
      ? theme.typography.pxToRem(12)
      : size === "medium"
      ? theme.typography.pxToRem(16)
      : theme.typography.pxToRem(20),
  padding:
    size === "small"
      ? "6px 12px"
      : size === "medium"
      ? "8px 16px"
      : "10px 20px",
}));

const CustomButton = ({ size, ...props }) => {
  return (
    <StyledButton variant="contained" color="primary" size={size} {...props}>
      {props.children}
    </StyledButton>
  );
};

export default CustomButton;
