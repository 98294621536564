import holidayinn from "../images/partners/holidayinn.jpeg";
import hyatt from "../images/partners/hyatt.jpeg";
import lemotree from "../images/partners/lemotree.jpeg";
import mariott from "../images/partners/mariott.jpeg";
import parkhotels from "../images/partners/parkhotels.jpeg";
import Radisson from "../images/partners/Radisson.jpeg";
import Sheratom from "../images/partners/Sheratom.jpeg";
import starwoodk from "../images/partners/starwoodk.jpeg";

export const partnerImages = [
  {
    id: 1,
    src: holidayinn,
    alt: "Holiday Inn",
    title: "Holiday Inn",
  },
  {
    id: 2,
    src: hyatt,
    alt: "Hyatt",
    title: "Hyatt",
  },
  {
    id: 3,
    src: lemotree,
    alt: "Lemo Tree",
    title: "Lemo Tree",
  },
  {
    id: 4,
    src: mariott,
    alt: "Mariott",
    title: "Mariott",
  },
  {
    id: 5,
    src: parkhotels,
    alt: "Park Hotels",
    title: "Park Hotels",
  },
  {
    id: 6,
    src: Radisson,
    alt: "Radisson",
    title: "Radisson",
  },
  {
    id: 7,
    src: Sheratom,
    alt: "Sheratom",
    title: "Sheratom",
  },
  {
    id: 8,
    src: starwoodk,
    alt: "Starwoodk",
    title: "Starwoodk",
  },
];
