import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DegreeCourses } from "../../utils/courses";
import { styled } from "@mui/material/styles";
import Card from "../../component/Card";
import "../../style.css";

export default function Zoom1() {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#1E73BE14",
        display: "flex",
        justifyContent: "center",
        height: "fit-content",
        padding: "3rem 0 5rem 0",
      }}
    >
      <Grid item xs={12} lg={12}>
        <Title>Our Degree Courses</Title>
      </Grid>

      {DegreeCourses.map((item) => {
        return (
          <Grid
            item
            xs={12}
            lg={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Card {...item} />
          </Grid>
        );
      })}
    </Grid>
  );
}

const Title = styled(Typography)({
  textAlign: "center",
  fontFamily: "Noto Serif",
  fontSize: "40px",
  fontWeight: 600,
  lineHeight: "48px",
  letterSpacing: " 0.02em",
  "@media (max-width: 600px)": {
    fontSize: "37px",
    margin: "1rem 0.4rem",
  },
});
