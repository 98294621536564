import bar1 from "../images/sbihm/bar-1.jpg";
import bar2 from "../images/sbihm/bar-2.jpg";
import computerlab1 from "../images/sbihm/computerlab-1.jpg";
import computerlab2 from "../images/sbihm/computerlab-2.jpg";
import trip1 from "../images/sbihm/trip-1.jpg";
import trip2 from "../images/sbihm/trip-2.jpg";
import trip3 from "../images/sbihm/trip-3.jpg";
import trip4 from "../images/sbihm/trip-4.jpg";
import trip5 from "../images/sbihm/trip-5.jpg";
import trip6 from "../images/sbihm/trip-6.jpg";
import trip7 from "../images/sbihm/trip-7.jpg";
import gallery1 from "../images/sbihm/gallery_1.png";
import gallery2 from "../images/sbihm/gallery_2.png";
import gallery3 from "../images/sbihm/gallery_3.png";
import gallery4 from "../images/sbihm/gallery_4.png";
import gallery5 from "../images/sbihm/gallery_5.png";
import gallery6 from "../images/sbihm/gallery_6.png";
import gallery7 from "../images/sbihm/gallery_7.png";
import gallery8 from "../images/sbihm/gallery_8.png";

export const imageList = [
  {
    id: 1,
    src: bar1,
    title: "Image 1",
    cols: 4,
    rows: 3,
  },
  {
    id: 2,
    src: bar2,
    title: "Image 2",
    cols: 2,
    rows: 2,
  },
  {
    id: 3,
    src: computerlab1,
    title: "Image 3",
    cols: 4,
    rows: 2,
  },
  {
    id: 4,
    src: computerlab2,
    title: "Image 4",
    cols: 2,
    rows: 1,
  },
  {
    id: 5,
    src: trip1,
    title: "Image 5",
    cols: 3,
    rows: 2,
  },
  {
    id: 6,
    src: trip2,
    title: "Image 6",
    cols: 2,
    rows: 1,
  },
  {
    id: 7,
    src: trip3,
    title: "Image 7",
    cols: 4,
    rows: 2,
  },
  {
    id: 8,
    src: trip4,
    title: "Image 8",
    cols: 2,
    rows: 2,
  },
  {
    id: 9,
    src: trip5,
    title: "Image 9",
    cols: 2,
    rows: 4,
  },
  {
    id: 10,
    src: trip6,
    title: "Image 10",
    cols: 2,
    rows: 3,
  },
  // {
  //   id: 11,
  //   src: trip7,
  //   title: "Image 11",
  //   cols: 2,
  //   rows: 3,
  // },
  // {
  //   id: 12,
  //   src: gallery1,
  //   title: "Image 12",
  //   cols: 2,
  //   rows: 2,
  // },
  {
    id: 13,
    src: gallery2,
    title: "Image 13",
    cols: 2,
    rows: 3,
  },
  // {
  //   id: 14,
  //   src: gallery3,
  //   title: "Image 14",
  //   cols: 2,
  //   rows: 2,
  // },
  {
    id: 15,
    src: gallery4,
    title: "Image 15",
    cols: 2,
    rows: 3,
  },
  {
    id: 16,
    src: gallery5,
    title: "Image 16",
    cols: 2,
    rows: 4,
  },
  {
    id: 17,
    src: gallery6,
    title: "Image 17",
    cols: 2,
    rows: 2,
  },
  {
    id: 18,
    src: gallery7,
    title: "Image 18",
    cols: 3,
    rows: 2,
  },
  // {
  //   id: 19,
  //   src: gallery8,
  //   title: "Image 19",
  //   cols: 3,
  //   rows: 2,
  // },
];
