import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Hidden from "@mui/material/Hidden";
import { imageList } from "../../utils/imagelist";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

//write a function whihc will caulcate aspect ratio based on image size and rows and cols
// async function getImageAspectRatio(imageUrl) {
//   const image = new Image();
//   image.src = imageUrl;
//   await image.decode();
//   return getEasyFraction(image.width, image.height);
// }

export default function QuiltedImageList() {
  return (
    <>
      <Hidden mdDown>
        <ImageList
          sx={{ width: "80%", height: "fit-content" }}
          variant="quilted"
          cols={8}
          rowHeight={121}
        >
          {imageList.map((item) => (
            <ImageListItem
              key={item.id}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.src, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Hidden>
      <Hidden lgUp>
        <ImageList
          sx={{ width: "80%", height: "fit-content" }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {imageList.map((item) => (
            <ImageListItem key={item.id} cols={2} rows={2}>
              <img
                {...srcset(item.src, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Hidden>
    </>
  );
}
