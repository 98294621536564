import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { slidingImages } from "../../utils/images";
import LazyLoad from "react-lazy-load";

const ImageSliderWrapper = styled(Box)({
  width: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
});

const ImageWrapper = styled(Box)({
  display: "inline-block",
  height: "300px",
  marginRight: "10px",
  "& img": {
    height: "300px",
    width: "30rem",
    height: "20rem",
    marginLeft: "10px",
  },
});

const ImageSlider = () => {
  const [translateValue, setTranslateValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateValue((prevValue) => {
        if (prevValue <= -100 * (slidingImages.length - 2)) {
          return 0;
        }
        return prevValue - 100;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [slidingImages.length]);

  return (
    <ImageSliderWrapper>
      {slidingImages.map((image, index) => (
        <ImageWrapper
          key={index}
          style={{
            transform: `translateX(${translateValue}%)`,
            transition: "transform ease-out 0.45s",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <LazyLoad height={300}>
            <img src={image} alt="" />
          </LazyLoad>
        </ImageWrapper>
      ))}
    </ImageSliderWrapper>
  );
};

export default ImageSlider;
