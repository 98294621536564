import React from "react";
import { Grid } from "@mui/material";
import C1 from "./C1";
import C2 from "./C2";
import C3 from "./C3";
import C4 from "./C4";
import C5 from "./C5";
import C6 from "./C6";
import H3 from "../Home/H3";
import Footer from "../../component/Footer";
import { useParams } from "react-router-dom";
import Navbar from "../../component/Navbar";
import { CertificateCourses } from "../../utils/courses";
import FlashScreen from "../../component/splash2";

export default function CoursePage() {
  const { slug } = useParams();
  const course = CertificateCourses.find((course) => course.slug === slug);

  React.useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  return (
    <Grid
      container
      sx={{ justifyContent: "center", marginTop: { lg: "6rem" } }}
    >
      <FlashScreen view />
      <Grid xs={12} lg={12} sm={12}>
        <Navbar />
      </Grid>
      <Grid xs={12}>
        <C1
          name={course.name}
          image={course.pageImage}
          courseType={"Certificate Course"}
        />
      </Grid>
      <Grid xs={12}>
        <C2 {...course} />
      </Grid>
      <Grid xs={12}>
        <C3 {...course} />
      </Grid>
      <Grid xs={12} sx={{ marginTop: 5 }}>
        <C4 />
      </Grid>
      <Grid xs={12}>
        <C5 />
      </Grid>
      <Grid xs={12}>
        <C6 name={course.name} />
      </Grid>
      <Grid xs={12}>
        <H3 />
      </Grid>
      <Grid xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}
