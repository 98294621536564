import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CertificateCourses } from "../../utils/courses";
import Card from "../../component/Card";
import "../../style.css";

export default function Zoom1() {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "fit-content",
        padding: "3rem 0 5rem 0",
        width: "100%",
        backgroundColor: "#1E73BE14",
      }}
    >
      <Grid item xs={12} lg={12}>
        <Typography
          style={{
            fontFamily: "Noto Serif",
            fontSize: "40px",
            fontWeight: 600,
            lineHeight: "48px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#0A253D",
            marginBottom: "2.5rem",
          }}
        >
          Our Certificate Courses
        </Typography>
      </Grid>

      {CertificateCourses.map((item) => {
        return (
          <Grid
            item
            xs={12}
            lg={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Card {...item} vertical />
          </Grid>
        );
      })}
    </Grid>
  );
}
