import {
  Divider,
  Grid,
  Hidden,
  Typography,
  MenuItem,
  Paper,
  MenuList,
  ListItemText,
  IconButton,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  DegreeCourses,
  CertificateCourses,
  DiplomaCourses,
} from "../utils/courses";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function Footer() {
  const [openDegree, setOpenDegree] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const [openDiploma, setOpenDiploma] = useState(false);

  const navigate = useNavigate();

  const certificates = [
    "Certificate In Front Office Operation",
    "Certificate In Accomodation Operation",
    "Certificate In Kitchen Operation",
    "Certificate In Restaurant & Bar Operation",
  ];

  return (
    <Grid
      container
      sx={{
        bgcolor: "#0A253D",
        padding: "5rem  1rem",
        position: "relative",
      }}
    >
      <Grid lg={3}>
        <div
          style={{
            fontSize: "32px",
            fontWeight: 500,
            lineHeight: "56px",
            letterSpacing: "0em",
            color: "#FFFFFF",
          }}
        >
          SBIHM Kharagpur
        </div>

        <div
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0em",
            color: "#96a8bc",
          }}
        >
          © Copyright 2023 by SOUTH BENGAL INSTITUTE OF HOTEL MANAGEMENT, All
          Rights Reserved.
        </div>

        <div
          style={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <a style={{ marginTop: 10 }} href="https://www.facebook.com/sbihmkgp">
            <FacebookIcon htmlColor="white" />
          </a>
          {/* <div style={{ marginLeft: 10, marginTop: 10 }}>
            <LinkedInIcon htmlColor="white" />
          </div>
          <div style={{ marginLeft: 10, marginTop: 10 }}>
            <InstagramIcon htmlColor="white" />
          </div> */}
        </div>
      </Grid>

      <Hidden smDown xsDown mdDown>
        <Grid
          lg={3}
          sx={{
            // backgroundColor: "grey",
            paddingLeft: "80px",
            paddingTop: "20px",
          }}
        >
          <div
            style={{
              fontFamily: "Roboto Slab",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#FFFFFF",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            Degree Courses
          </div>
          <div
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#96a8bc",
            }}
          >
            {DegreeCourses.map((course) => (
              <StyledLink
                id={course.id}
                onClick={() => navigate(`/degree-courses/${course.slug}`)}
              >
                {course.name}
              </StyledLink>
            ))}
          </div>
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid
          lg={3}
          sx={{
            paddingLeft: "80px",
            paddingTop: "20px",
          }}
        >
          <div
            style={{
              fontFamily: "Roboto Slab",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#FFFFFF",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            Diploma Courses
          </div>
          <div
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,

              textAlign: "left",
              color: "#96a8bc",
            }}
          >
            {DiplomaCourses.map((course) => (
              <StyledLink
                id={course.id}
                onClick={() => navigate(`/diploma-courses/${course.slug}`)}
              >
                {course.name}
              </StyledLink>
            ))}
          </div>
        </Grid>
      </Hidden>

      <Hidden mdDown>
        <Grid
          lg={3}
          sx={{
            paddingLeft: "80px",
            paddingTop: "20px",
          }}
        >
          <div
            style={{
              fontFamily: "Roboto Slab",
              fontSize: "24px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0em",
              color: "#FFFFFF",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            Certificate Courses
          </div>
          <div
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,

              textAlign: "left",
              color: "#96a8bc",
            }}
          >
            {CertificateCourses.map((course) => (
              <StyledLink
                id={course.id}
                onClick={() => navigate(`/certificate-courses/${course.slug}`)}
              >
                {course.name}
              </StyledLink>
            ))}
          </div>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          xs={12}
          sx={{
            marginTop: "30px",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "28px",
                fontWeight: 400,
                lineHeight: "56px",
                letterSpacing: "0em",
                color: "#FFFFFF",
              }}
              onClick={() => setOpenDegree(!openDegree)}
            >
              Degree Courses
            </div>
            <div>
              <IconButton onClick={() => setOpenDegree(!openDegree)}>
                <KeyboardArrowDownIcon
                  onClick={() => setOpenDegree(!openDegree)}
                  fontSize="large"
                  htmlColor="white"
                />
              </IconButton>
            </div>
          </div>

          <div>
            {openDegree && (
              <div style={{ textAlign: "left" }}>
                <ul>
                  {DegreeCourses.map((item) => (
                    <StyledLink
                      key={item.id}
                      onClick={() => navigate(`/degree-courses/${item.slug}`)}
                    >
                      {item.name}
                    </StyledLink>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Grid>

        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "grey",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        ></div>
        <Grid xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "28px",
                fontWeight: 400,
                lineHeight: "56px",
                letterSpacing: "0em",
                color: "#FFFFFF",
              }}
              onClick={() => setOpenDiploma(!openDiploma)}
            >
              Diploma Courses
            </div>
            <div>
              <IconButton>
                <KeyboardArrowDownIcon
                  onClick={() => setOpenDiploma(!openDiploma)}
                  fontSize="large"
                  htmlColor="white"
                />
              </IconButton>
            </div>
          </div>
          <div>
            {openDiploma && (
              <div style={{ textAlign: "left" }}>
                <ul>
                  {DiplomaCourses.map((item) => (
                    <StyledLink
                      key={item.id}
                      onClick={() => navigate(`/diploma-courses/${item.slug}`)}
                    >
                      {item.name}
                    </StyledLink>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div
            style={{
              height: "1px",
              width: "100%",
              backgroundColor: "grey",
            }}
          ></div>{" "}
        </Grid>

        <Grid xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "28px",
                fontWeight: 400,

                lineHeight: "56px",
                letterSpacing: "0em",
                color: "#FFFFFF",
              }}
              onClick={() => setOpenCertificate(!openCertificate)}
            >
              Certificate Courses
            </div>
            <div>
              <IconButton onClick={() => setOpenCertificate(!openCertificate)}>
                <KeyboardArrowDownIcon fontSize="large" htmlColor="white" />
              </IconButton>
            </div>
          </div>

          <div>
            {openCertificate && (
              <div style={{ textAlign: "left" }}>
                <ul>
                  {CertificateCourses.map((certificate) => (
                    <StyledLink
                      key={certificate.id}
                      onClick={() =>
                        navigate(`/certificate-courses/${certificate.slug}`)
                      }
                    >
                      {certificate.name}
                    </StyledLink>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Grid>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "grey",
            marginBottom: "10px",
          }}
        ></div>
      </Hidden>
      <NavFooter>
        Made with <FavoriteIcon style={{ margin: "0 10px" }} /> by
        <a href="https://www.xgenlab.tech">Xgenlab.Tech</a>{" "}
      </NavFooter>
    </Grid>
  );
}

//create mui styled link
const StyledLink = styled("div")({
  fontFamily: "DM Sans",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#96a8bc",
  cursor: "pointer",
  marginTop: "5px",
  "&:hover": {
    color: "white",
  },
});

const NavFooter = styled("div")({
  display: "flex",
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "60px",
  color: "white",
  alignItems: "center",
  justifyContent: "center",

  "& a": {
    textDecoration: "none",
    padding: "0 10px",
  },
});
