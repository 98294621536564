import React, { useState } from "react";
import {
  ImageList,
  ImageListItem,
  Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const images = [
  {
    id: 1,
    url: "https://picsum.photos/id/1018/400/400",
    aspectRatio: 1,
  },
  {
    id: 2,
    url: "https://picsum.photos/id/1025/400/300",
    aspectRatio: 4 / 3,
  },
  {
    id: 3,
    url: "https://picsum.photos/id/1015/400/500",
    aspectRatio: 5 / 4,
  },
  {
    id: 4,
    url: "https://picsum.photos/id/1019/400/600",
    aspectRatio: 3 / 2,
  },
  {
    id: 5,
    url: "https://picsum.photos/id/1016/400/800",
    aspectRatio: 2 / 1,
  },
];

const ImageItem = styled(ImageListItem)(({ theme, aspectRatio }) => ({
  // set the height of the item based on its aspect ratio
  height: `calc(${theme.spacing(30)} + ${theme.spacing(2)})`,
  aspectRatio,
  "& img": {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
}));

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <ImageList
        cols={5}
        gap={2}
        variant="quilted"
        sx={{ width: 500, height: 450 }}
      >
        {images.map((image) => (
          <ImageItem
            key={image.id}
            aspectRatio={image.aspectRatio}
            onClick={() => openModal(image)}
          >
            <img src={image.url} alt={`Image ${image.id}`} />
          </ImageItem>
        ))}
      </ImageList>

      <Modal
        open={!!selectedImage}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <img src={selectedImage?.url} alt={`Image ${selectedImage?.id}`} />
        </div>
      </Modal>
    </>
  );
};

export default Gallery;
