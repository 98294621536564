import { Grid, Typography, TextField, Button, Hidden } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { blueGrey } from "@mui/material/colors";
import {
  DegreeCourses,
  DiplomaCourses,
  CertificateCourses,
  indianStates,
} from "../../utils/courses";
import { contactEmail } from "../../utils/reviews";
import Loader from "../../component/Loader";

export default function Apply() {
  const courses = [...DegreeCourses, ...DiplomaCourses, ...CertificateCourses];
  const [loading, setLoading] = useState(false);
  const fillCategoryDropDown = () => {
    return courses.map((item) => {
      return (
        <MenuItem value={item.name} id={item.id}>
          {item.name}
        </MenuItem>
      );
    });
  };

  const [personalInfo, setPersonalInformation] = useState({
    fullName: "",
    email: "",
    phone: "",
    dob: "",
    bloodGroup: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    sex: "",
    fatherName: "",
    motherName: "",
    guardianMobileNumber: "",
  });
  const [err, setErr] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const checkError = () => {
    if (personalInfo.fullName === "") {
      setErr((err) => ({ ...err, name: true }));
      return true;
    }
    if (personalInfo.email === "") {
      setErr((err) => ({ ...err, email: true }));
      return true;
    }
    if (personalInfo.phone === "") {
      setErr((err) => ({ ...err, phone: true }));
      return true;
    }
    return false;
  };

  const [languagesKnown, setLanguagesKnown] = useState([
    {
      lName: "",
      read: false,
      write: false,
      speak: false,
    },
    {
      lName: "",
      read: false,
      write: false,
      speak: false,
    },
    {
      lName: "",
      read: false,
      write: false,
      speak: false,
    },
  ]);

  const [language1, setLanguage1] = useState({
    lName: "",
    read: false,
    write: false,
    speak: false,
  });
  const [language2, setLanguage2] = useState({
    lName: "",
    read: false,
    write: false,
    speak: false,
  });
  const [language3, setLanguage3] = useState({
    lName: "",
    read: false,
    write: false,
    speak: false,
  });

  const [education, setEducation] = useState([
    {
      selected: false,
      name: "Secondary Examination (10th)",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Higher Secondary Examination (12th)",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Diploma",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Graduate Degree",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
    {
      selected: false,
      name: "Post - Graduation Degree",
      board: "",
      year: "",
      marks: "",
      subject: "",
    },
  ]);

  const handlePersonalInfoChange = (event) => {
    setPersonalInformation({
      ...personalInfo,
      [event.target.name]: event.target.value,
    });

    if (event.target.value !== "")
      setErr(() => ({ ...err, [event.target.name]: false }));
  };

  const handleLanguagesChange = (event, index) => {
    let temp = languagesKnown;
    temp[index][event.target.name] = event.target.checked || event.target.value;
    setLanguagesKnown(() => temp);
    console.log(languagesKnown);
  };

  const handleEducationChange = (event, index) => {
    let temp = [...education];
    temp[index][event.target.name] = event.target.value;
    setEducation(temp);
  };

  const handleSubmit = () => {
    if (checkError()) return;
    setLoading(true);

    let payload = {
      ...personalInfo,
      language1: `Name : ${language1.lname}, ||  read :  ${language1.read}, ||  write :  ${language1.write},  ||  speak :  ${language1.speak}`,
      language2: `Name : ${language2.lname},  ||  read :  ${language2.read}, ||  write :  ${language2.write},  ||  speak :  ${language2.speak}`,
      language3: `Name : ${language3.lname},  ||  read :  ${language3.read}, ||  write :  ${language3.write},  ||  speak :  ${language3.speak}`,

      [education[0]
        .name]: `Subject :  ${education[0].subject} ||  Board :  ${education[0].board}  ||  Year of passing :  ${education[0].year}  ||  Marks :  ${education[0].marks}`,
      [education[1]
        .name]: `Subject :  ${education[1].subject} ||  Board :  ${education[1].board} ||   Year of passing :  ${education[1].year}  ||  Marks :  ${education[1].marks}`,
      [education[2]
        .name]: `Subject :  ${education[2].subject} ||  Board :  ${education[2].board} ||  Year of passing :  ${education[2].year}  ||  Marks :  ${education[2].marks}`,
      [education[3]
        .name]: `Subject :  ${education[3].subject}  || Board :  ${education[3].board}  ||  Year of passing :  ${education[3].year}  ||  Marks :  ${education[3].marks}`,
      [education[4]
        .name]: `Subject :  ${education[4].subject} ||  Board :  ${education[4].board}  ||  Year of passing :  ${education[4].year}  ||  Marks :  ${education[4].marks}`,
    };

    fetch("https://formsubmit.co/ajax/" + contactEmail, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  return (
    <Grid container>
      <Loader loading={loading} />
      <Hidden smDown>
        <Grid item xs={12} sx={{ bgcolor: "#FFFFF" }}>
          <Typography
            sx={{
              fontFamily: "Noto Serif",
              fontSize: "40px",
              fontWeight: 600,
              lineHeight: "48px",
              letterSpacing: "0em",
              textAlign: "center",
              color: "#523D1C",
            }}
          >
            Apply Now
          </Typography>
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "36px",
              letterSpacing: "0.02em",
              textAlign: "center",
              color: "#666666",
            }}
          >
            {`Academic Year ${new Date().getFullYear()} - ${
              new Date().getFullYear() + 1
            }`}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            bgcolor: "#1E73BE14",
            margin: 10,
            padding: 2,
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28px",
              letterSpacing: "0.02em",
              // textAlign: "center",
              color: "#000000",
            }}
          >
            Application Form
          </Typography>
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "28px",
              letterSpacing: "0.02em",
              // textAlign: "center",
              color: "#666666",
              marginTop: "10px",
            }}
          >
            Fill out the application form and our team will get back to you
          </Typography>

          <Grid
            sx={{
              bgcolor: "#FFFFFF",
              width: "100%",
              borderRadius: 2,
              marginTop: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  backgroundColor: "#1E73BE",
                  textAlign: "center",
                  fontSize: 14,
                  marginTop: 1.5,
                  marginRight: 2,
                  marginLeft: 2,
                }}
              >
                1
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "20px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.02em",
                  color: "#000000",
                  marginTop: "10px",
                }}
              >
                Personal Information
              </Typography>
            </div>

            <Grid sx={{ margin: "15px" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Courses</InputLabel>
                <Select
                  // value={categoryId}
                  // label="Category Name"
                  // onChange={(event) => setCategoryId(event.target.value)}
                  // InputProps={{ style: { borderRadius: 50 } }}
                  style={{ borderRadius: 50 }}
                  name="course"
                  value={personalInfo.course}
                  onChange={handlePersonalInfoChange}
                >
                  {fillCategoryDropDown()}
                </Select>
              </FormControl>
              <TextField
                size="small"
                label="Applicant’s Full Name"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="fullName"
                value={personalInfo.fullName}
                onChange={handlePersonalInfoChange}
                required
                error={err.name}
              />
              <TextField
                size="small"
                label="Applicant’s Mobile No."
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="phone"
                value={personalInfo.phone}
                onChange={handlePersonalInfoChange}
                required
                error={err.phone}
              />
              <TextField
                size="small"
                label="Applicant’s Email"
                variant="outlined"
                fullWidth
                InputProps={{ style: { borderRadius: 50 } }}
                style={{ marginTop: 10 }}
                name="email"
                value={personalInfo.email}
                onChange={handlePersonalInfoChange}
                required
                error={err.email}
              />
              <TextField
                size="small"
                label="Communicating Address"
                multiline
                rows={3}
                maxRows={5}
                fullWidth
                InputProps={{ style: { borderRadius: 20 } }}
                style={{ marginTop: 10 }}
                name="address"
                value={personalInfo.address}
                onChange={handlePersonalInfoChange}
              />
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={3} sx={{ marginTop: "10px" }}>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                      // value={categoryId}
                      label="Category Name"
                      // onChange={(event) => setCategoryId(event.target.value)}
                      // InputProps={{ style: { borderRadius: 50 } }}
                      style={{ borderRadius: 50 }}
                      name="state"
                      value={personalInfo.state}
                      onChange={handlePersonalInfoChange}
                    >
                      {indianStates.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={3} sx={{ marginTop: "10px" }}>
                  <TextField
                    size="small"
                    label="District"
                    variant="outlined"
                    fullWidth
                    InputProps={{ style: { borderRadius: 50 } }}
                    name="district"
                    value={personalInfo.district}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
                <Grid xs={3} sx={{ marginTop: "10px" }}>
                  <TextField
                    size="small"
                    label="Pincode"
                    variant="outlined"
                    fullWidth
                    InputProps={{ style: { borderRadius: 50 } }}
                    name="pincode"
                    value={personalInfo.pincode}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={5} sx={{ marginTop: "10px" }}>
                  <TextField
                    placeholder="Date Of Birth"
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      style: { borderRadius: 50 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon />
                        </InputAdornment>
                      ),
                    }}
                    name="dob"
                    value={personalInfo.dob}
                    onChange={handlePersonalInfoChange}
                    type="date"
                  />
                </Grid>
                <Grid xs={5} item sx={{ marginTop: "10px" }}>
                  <TextField
                    size="small"
                    label="Blood Group"
                    variant="outlined"
                    fullWidth
                    InputProps={{ style: { borderRadius: 50 } }}
                    name="bloodGroup"
                    value={personalInfo.bloodGroup}
                    onChange={handlePersonalInfoChange}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ marginLeft: 2 }}>
                <TextField
                  variant="outlined"
                  placeholder="Father’s / Guardian’s Name"
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 15,
                    },
                  }}
                  name="fatherName"
                  value={personalInfo.fatherName}
                  onChange={handlePersonalInfoChange}
                />
                <TextField
                  variant="outlined"
                  placeholder="Mother's Name"
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 15,
                    },
                  }}
                  name="motherName"
                  value={personalInfo.motherName}
                  onChange={handlePersonalInfoChange}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Guardian’s Mobile No."
                  fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="guardianMobileNumber"
                  value={personalInfo.guardianMobileNumber}
                  onChange={handlePersonalInfoChange}
                />
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    textAlign: "left",
                    color: "#666666",
                    marginTop: "10px",
                  }}
                >
                  Sex :
                </Typography>

                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="male"
                    row
                    name="sex"
                    value={personalInfo.sex}
                    onChange={(e) => handlePersonalInfoChange(e)}
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>

                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    textAlign: "left",
                    color: "#666666",
                    marginTop: "10px",
                  }}
                >
                  Language Known :
                </Typography>

                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "center",
                    marginTop: 2,
                  }}
                >
                  <Typography>1</Typography>
                  <TextField
                    variant="outlined"
                    // fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 50,
                        marginLeft: 10,
                        marginRight: 50,
                      },
                    }}
                    name="lName"
                    value={language1.lName}
                    onChange={(e) =>
                      setLanguage1((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                          name="read"
                          checked={language1.read}
                          onChange={(e) =>
                            setLanguage1((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.checked,
                            }))
                          }
                        />
                      }
                      label="Read"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      name="write"
                      checked={language1.write}
                      onChange={(e) =>
                        setLanguage1((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                      label="Write"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Speak"
                      name="speak"
                      checked={language1.speak}
                      onChange={(e) =>
                        setLanguage1((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "center",
                    marginTop: 2,
                  }}
                >
                  <Typography>2</Typography>
                  <TextField
                    variant="outlined"
                    // fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 50,
                        marginLeft: 10,
                        marginRight: 50,
                      },
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <CalendarMonthIcon />
                      //   </InputAdornment>
                      // ),
                    }}
                    name="lName"
                    value={language2.lName}
                    onChange={(e) =>
                      setLanguage2((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Read"
                      name="read"
                      checked={language2.read}
                      onChange={(e) =>
                        setLanguage2((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Write"
                      name="write"
                      checked={language2.write}
                      onChange={(e) =>
                        setLanguage2((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Speak"
                      name="speak"
                      checked={language2.speak}
                      onChange={(e) =>
                        setLanguage2((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: "center",
                    marginTop: 2,
                    paddingBottom: 6,
                  }}
                >
                  <Typography>3</Typography>
                  <TextField
                    variant="outlined"
                    // fullWidth
                    size="small"
                    InputProps={{
                      style: {
                        borderRadius: 50,
                        marginLeft: 10,
                        marginRight: 50,
                      },
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <CalendarMonthIcon />
                      //   </InputAdornment>
                      // ),
                    }}
                    name="lName"
                    value={language3.lName}
                    onChange={(e) =>
                      setLanguage3((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Read"
                      name="read"
                      checked={language3.read}
                      onChange={(e) =>
                        setLanguage3((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Write"
                      name="write"
                      checked={language3.write}
                      onChange={(e) =>
                        setLanguage3((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: blueGrey[900],
                            "&.Mui-checked": {
                              color: blueGrey[900],
                            },
                          }}
                        />
                      }
                      label="Speak"
                      name="speak"
                      checked={language3.speak}
                      onChange={(e) =>
                        setLanguage3((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.checked,
                        }))
                      }
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              bgcolor: "#FFFFFF",
              width: "100%",
              borderRadius: 2,
              marginTop: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                  backgroundColor: "#1E73BE",
                  textAlign: "center",
                  fontSize: 14,
                  marginTop: 1.5,
                  marginRight: 2,
                  marginLeft: 2,
                }}
              >
                2
              </Typography>
              <div>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "24px",
                    letterSpacing: "0.02em",
                    color: "#000000",
                    marginTop: "10px",
                  }}
                >
                  Educational Qualifications
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "28px",
                    letterSpacing: "0.02em",
                    color: "#666666",
                  }}
                >
                  Check the examinations you’ve completed/graduated
                </Typography>
              </div>
            </div>

            <Grid item sx={{ margin: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked
                    sx={{
                      color: blueGrey[900],
                      "&.Mui-checked": {
                        color: blueGrey[900],
                      },
                    }}
                  />
                }
                label="Secondary Examination (10th)"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="subject"
                  value={education[0].subject}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="board"
                  value={education[0].board}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="year"
                  value={education[0].year}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="marks"
                  value={education[0].marks}
                  onChange={(e) => handleEducationChange(e, 0)}
                />
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked
                    sx={{
                      color: blueGrey[900],
                      "&.Mui-checked": {
                        color: blueGrey[900],
                      },
                    }}
                  />
                }
                label="Higher Secondary Examination (12th)"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="subject"
                  value={education[1].subject}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="board"
                  value={education[1].board}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="year"
                  value={education[1].year}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="marks"
                  value={education[1].marks}
                  onChange={(e) => handleEducationChange(e, 1)}
                />
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked
                    sx={{
                      color: blueGrey[900],
                      "&.Mui-checked": {
                        color: blueGrey[900],
                      },
                    }}
                  />
                }
                label="Diploma"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="subject"
                  value={education[2].subject}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="board"
                  value={education[2].board}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="year"
                  value={education[2].year}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="marks"
                  value={education[2].marks}
                  onChange={(e) => handleEducationChange(e, 2)}
                />
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked
                    sx={{
                      color: blueGrey[900],
                      "&.Mui-checked": {
                        color: blueGrey[900],
                      },
                    }}
                  />
                }
                label="Graduate Degree"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="subject"
                  value={education[3].subject}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="board"
                  value={education[3].board}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="year"
                  value={education[3].year}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="marks"
                  value={education[2].marks}
                  onChange={(e) => handleEducationChange(e, 3)}
                />
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked
                    sx={{
                      color: blueGrey[900],
                      "&.Mui-checked": {
                        color: blueGrey[900],
                      },
                    }}
                  />
                }
                label="Post - Graduation Degree"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Subject"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="subject"
                  value={education[4].subject}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Board Council"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="board"
                  value={education[4].board}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Year of Passing"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="year"
                  value={education[4].year}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Marks"
                  // fullWidth
                  InputProps={{
                    style: {
                      borderRadius: 50,
                      marginLeft: 2,
                      marginTop: 10,
                      marginBottom: 15,
                    },
                  }}
                  name="marks"
                  value={education[4].marks}
                  onChange={(e) => handleEducationChange(e, 4)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item sx={{ textAlign: "center", marginTop: 5 }}>
            <Button
              sx={{ borderRadius: 15 }}
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
}
