import React, { useState } from "react";
import { Grid, Hidden, Typography } from "@mui/material";
import IncrementalCounter from "../../component/IncrementalCounter";
export default function Count() {
  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
      id="statistics-section"
    >
      <Grid
        lg={4}
        xs={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IncrementalCounter startValue={0} endValue={9} />

        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Course Offered
        </Typography>
      </Grid>

      <Grid
        lg={4}
        xs={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IncrementalCounter startValue={0} endValue={60} />

        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Yearly Graduates
        </Typography>
      </Grid>

      {/* <Grid
        lg={3}
        xs={6}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IncrementalCounter startValue={0} endValue={3} />

        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          State Ranked
        </Typography>
      </Grid> */}

      <Grid
        lg={4}
        xs={12}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IncrementalCounter startValue={0} endValue={12} />

        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            color: "#0A253D",
            marginTop: "10px",
          }}
        >
          Partners
        </Typography>
      </Grid>
    </Grid>
  );
}
