import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Navbar from "../../component/Navbar";
import Slider1 from "./HeroCarousel";
import H1 from "./H1";
import H2 from "./certificateOm";
import H3 from "./H3";
import Count from "./Count";
import Partners from "./Partners";
import Slider3 from "./reviews";
import Footer from "../../component/Footer";
import DegreeCourses from "./DegreeCourses";
import DiplomaCourse from "./DiplomaCourse";
import Zoom2 from "./CertificateCourse";
import Map from "./Map";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Dialog from "./Dialog";
import TopBanner from "./TopBanner";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PrimaryButton from "../../component/Primarybutton";
import SecondaryButton from "../../component/Secondarybutton";
import FlashScreen from "../../component/splash2";
import { Hidden } from "@mui/material";

const OverlayDiv = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,

  backgroundImage:
    "linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.8), rgba(0,0,0,0.3))",

  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  paddingBottom: "2rem",

  "@media (max-width: 600px)": {
    backgroundImage: "linear-gradient(to top, rgba(0,0,0,1),  rgba(0,0,0,0))",
    alignItems: "center",
  },
});

const InstituteTitle = styled(Typography)({
  color: "white",
  textAlign: "center",
  fontSize: "4rem",
  // fontFamily: "Noto Serif",
  width: "100%",
  lineHeight: 1.3,
  fontWeight: "bold",
  padding: "0 0rem 2rem 0rem",

  "@media (max-width: 600px)": {
    fontSize: "2.3rem",
    margin: "1rem 0.1rem 2rem 0.1rem",
    padding: 0,
    width: "100%",
  },
});

const InstituteSubTitle = styled(Typography)({
  color: "#E5E5E5",
  textAlign: "center",
  fontSize: "1.2rem",

  width: "100%",
  lineHeight: 1,
  margin: "1rem 0rem 1rem 0rem",
  padding: "0 1.5rem",

  "@media (max-width: 600px)": {
    width: "80%",
    margin: "1rem 0rem 2rem 0rem",
    fontSize: "1rem",
  },
});

export default function Home({ dopen, setdOpen }) {
  const navigate = useNavigate();
  const sectionRef = React.useRef(null);
  const [open, setOpen] = useState(dopen ? false : true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setdOpen(true);
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);

  React.useEffect(() => {}, []);

  return (
    <Grid container>
      <FlashScreen view />
      <Grid xs={12} lg={12} sm={12}>
        <Navbar />
      </Grid>
      <Grid lg={12} xs={12} sm={12} sx={{ position: "relative" }}>
        <Slider1 />
        <OverlayDiv>
          <InstituteTitle>
            South Bengal <br /> Institute Of Hotel Management Kharagpur
          </InstituteTitle>
          <InstituteSubTitle>
            <strong> Authorized </strong> Vocational training partner by OSGU
            with ISO 9001:2015 Certified Institute
          </InstituteSubTitle>
          <Hidden mdUp>
            <ButtonContainer>
              <SecondaryButton
                size="medium"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </SecondaryButton>
              <PrimaryButton size="medium" onClick={() => navigate("/apply")}>
                Apply Now
              </PrimaryButton>
            </ButtonContainer>
          </Hidden>
        </OverlayDiv>
      </Grid>
      <Grid xs={12} sx={{ marginTop: 5 }}>
        <H1 />
      </Grid>
      <Grid xs={12} style={{ margin: "60px 0" }} ref={sectionRef}>
        <Count sectionRef={sectionRef} />
      </Grid>
      <Grid xs={12} sx={{ marginTop: 5 }}>
        <Partners />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 5 }}>
        <DegreeCourses />
      </Grid>
      <Grid item xs={12}>
        <DiplomaCourse />
      </Grid>
      <Grid lg={12} sx={{ marginTop: 5 }}>
        <Zoom2 />
      </Grid>
      <Grid lg={12}>
        <H2 />
      </Grid>
      <Grid xs={12}>
        <Slider3 />
      </Grid>
      <Grid xs={12}>
        <Map />
      </Grid>
      <Grid xs={12}>
        <H3 />
      </Grid>
      <Grid xs={12}>
        <Footer />
      </Grid>
      {open && <Dialog open={open} handleClose={handleClose} />}
    </Grid>
  );
}

const ButtonContainer = styled(Grid)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});
